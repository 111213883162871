import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IUser } from 'rifa.lib.common.ts/models'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { IUsersState, usersStateLoadFromApi } from '../../../redux/reducers/users.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HomeTypes } from '../../types'
import { HeaderComponent } from '../miscelaneous/header'
import { ResultSetItemComponent } from '../miscelaneous/resultSetItem'
import { UserComponent } from '../user'

export const UsersComponent = (props: {
  data: IUser[] | undefined,
  contentType: HomeTypes
}) => {
  const [contentType, setContentType] = useState(props.contentType)
  const [current, setCurrent] = useState<IUser>()
  const dispatch = useDispatch<any>()
  const { enqueueSnackbar } = useSnackbar()
  const usersState: IUsersState = useSelector((state: RootState) => state.usersState)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  useEffect(() => {
    if (!props.data && !usersState.payload)
      getData()
  }, [])

  const getData = () => {
    dispatch(usersStateLoadFromApi({
      onError: (msg: string | undefined) => {
        enqueueSnackbar(msg, {variant: 'error'})
      }
    }))
  }

  return contentType == HomeTypes.users ? <>
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Usuários'
      onAddClick={() => {
        setCurrent(undefined)
        setContentType(HomeTypes.user)
      }}
      onRefreshClick={getData}
    />

    {usersState.payload?.map(user => <ResultSetItemComponent
      key={user._id}
      showImg={true}
      imgProps={{
        src: `${dotEnvProductionState.payload?.APIURL}/img/${user.imgProfileUrl?.toString()}`
      }}
      label={`${user.login}, ${user.name}`}
      obj={user}
      style={user?.style}
      onClick={(obj) => {
        setCurrent(obj)
        setContentType(HomeTypes.user)
      }} />)}
  </> : <UserComponent
    onConfirm={() => {
      setContentType(HomeTypes.users)
      getData()
    }}
    onCancel={() => setContentType(HomeTypes.users)}
    onDelete={() => {
      setContentType(HomeTypes.users)
      getData()
    }}
    data={current} />
}