import { IconButton } from '@mui/material'
import { NumberUtils } from 'fwork.common.typescript'
import { ImageComponent } from 'fwork.react.apptemplate'
import { useSnackbar } from 'notistack'
import React from 'react'
import { MdOutlineContentCopy } from 'react-icons/md'
import { ITicketPayment, PaymentStatus, PaymentStatusDescriptions, TicketPaymentUtils } from 'rifa.lib.common.ts/models'
import DownTimerComponent from '../../../common/components/downTimer'

export const TicketPaymentTransactionComponent = (props: {
  transaction: ITicketPayment,
  onResetTimer?: () => void,
  onQrExpiration?: () => void
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const remainingQrExpirationSecs = TicketPaymentUtils.getRemainingExpirationSecs({
    transaction: props.transaction
  })

  const showTimer = [
    PaymentStatus.waitingConfirmation,
    PaymentStatus.waitingPayment
  ].indexOf(props.transaction.status === undefined ? -1 : props.transaction.status) !== -1
  const showQrExpired = TicketPaymentUtils.getQrExpired({
    transaction: props.transaction
  })
  const showQr = props.transaction.status === PaymentStatus.waitingPayment && remainingQrExpirationSecs > 0
  const showQrTimer = showQr
  const showStatus = props.transaction.status !== undefined && !showQrExpired

  let qrImg = props?.transaction?.qrImg != null ? props?.transaction?.qrImg?.toString() : null
  if (qrImg?.indexOf(',base64;'))
    qrImg = 'data:image;base64,' + qrImg

  return <>
    <div
      aria-label='TicketPaymentTransactionComponent'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10
      }}>
      {props.transaction.sl7PayId ? <div>Transação: {props.transaction.sl7PayId}</div> : <></>}
      {props.transaction.value ? <div style={{ fontWeight: 'bold' }}>Valor: R$ {NumberUtils.getNumberStr(props.transaction.value)}</div> : <></>}

      <div style={{ display: 'flex', alignItems: 'center' }}>
        {showStatus ?
          <div style={{
            display: 'flex',
            padding: 5,
            borderRadius: 5,
            background: props.transaction.status === PaymentStatus.canceled ? 'red' :
              props.transaction.status === PaymentStatus.paid ? 'green' : 'blue',
            color: 'white',
            textTransform: 'uppercase',
            gap: 5
          }}>
            {PaymentStatusDescriptions.filter(s => s.value === props.transaction.status)[0].description}

            {/* TIMER QUE ATUALIZA AS INFORMACOES DA TRANSACAO, CASO O status SEJA DIFERENTE DE AGUARDANDO CONFIRMACAO O TEXTO DELE DEVE SER OCULTO */}
            {showTimer ?
              <DownTimerComponent interval={30} onReset={props.onResetTimer} hideText={props.transaction.status !== PaymentStatus.waitingConfirmation} /> : <></>}

            {/* TIMER QUE EXIBE O TEMPO RESTANTE PARA PAGAR O QRCODE */}
            {showQrTimer ? <DownTimerComponent style={{ marginLeft: 5 }} interval={remainingQrExpirationSecs} onReset={props.onQrExpiration} /> : <></>}
          </div> : <></>}

        {showQrExpired ? <div style={{ color: 'red', marginLeft: 10 }}>Tempo para pagamento expirado</div> : <></>}
      </div>

      {showQr && qrImg ? <ImageComponent style={{ maxWidth: 300, maxHeight: 300 }} src={qrImg} /> : <></>}

      {showQr && props.transaction.qrUrl ? <div style={{
        background: 'silver',
        padding: 10,
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'calc(100% - 20px)'
      }}><span style={{ overflow: 'hidden' }}>{props.transaction.qrUrl.substring(0, 30)}</span>...<IconButton style={{ marginLeft: 10 }}>
          <MdOutlineContentCopy onClick={() => {
            if (props.transaction.qrUrl?.length) {
              enqueueSnackbar('Código QR copiado', { variant: "success" })
              navigator.clipboard.writeText(props.transaction.qrUrl.toString())
            }
          }} />
        </IconButton></div> : <></>}
    </div>
  </>
}

