import { ITicket, ITicketPayment } from 'rifa.lib.common.ts/models'
import { AxiosRequestConfig, AxiosStatic } from 'axios'
import { IApiGetResult, IApiResult } from 'fwork.common.typescript/api'
import { ApiClientUtils, BaseApiClient } from 'fwork.common.typescript/apiClient'
import { FilterQuery } from 'mongoose'
import { store } from '../redux/store'

export class TicketsApiClient extends BaseApiClient<ITicket, any, FilterQuery<ITicket>, ITicket, ITicket> {
  constructor(axios: AxiosStatic, apiUrl?: string | undefined) {
    const dotEnvProductionState = store.getState().dotEnvProductionState
    const baseUrl = apiUrl || `${dotEnvProductionState.payload?.APIURL}/tickets`
    super({
      apiUrl: baseUrl,
      axios
    })
  }

  async getPayments(args?: { id: string, config?: AxiosRequestConfig<ITicket> | undefined }): Promise<IApiResult<IApiGetResult<ITicketPayment[]>>> {
    return ApiClientUtils.get<any, FilterQuery<ITicket>>({
      axios: this.axios,
      apiUrl: `${this.apiUrl}/${args?.id}/payments`,
      config: args?.config
    })
  }

  async getWinners(args?: { where?: FilterQuery<ITicket>, select?: any, params?: any, config?: AxiosRequestConfig<ITicket> | undefined }): Promise<IApiResult<IApiGetResult<ITicket[]>>> {
    return ApiClientUtils.get({
      axios: this.axios,
      apiUrl: `${this.apiUrl}/winners`,
      where: args?.where,
      select: args?.select,
      params: args?.params,
      config: args?.config
    })
  }
}