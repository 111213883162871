import { ApiClientUtils } from 'fwork.common.typescript/apiClient'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FilterQuery } from 'mongoose'
import { IPrizeDraw } from 'rifa.lib.common.ts/models'
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from '../../../apiClient/prizeDraws'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HomeTypes } from '../../types'
import { HeaderComponent } from '../miscelaneous/header'
import { ResultSetItemComponent } from '../miscelaneous/resultSetItem'
import { PrizeDrawComponent } from '../prizeDraw'

export const PrizeDrawsComponent = (props: {
  data: IPrizeDraw[] | undefined,
  contentType: HomeTypes,
}) => {
  const [contentType, setContentType] = useState(props.contentType)
  const [current, setCurrent] = useState<IPrizeDraw>()
  const [localPrizeDraws, setLocalPrizeDraws] = useState<IPrizeDraw[] | undefined>()
  const { enqueueSnackbar } = useSnackbar()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  useEffect(() => {
    if (!localPrizeDraws?.length)
      loadPrizeDraws()
  }, [])

  const loadPrizeDraws = async () => {
    try {
      let where: FilterQuery<IPrizeDraw> | undefined

      const response = await new PrizeDrawsApiClient(axios).get({
        where
      })

      if (response.success){
        setLocalPrizeDraws(response.data?.payload)
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    {contentType == HomeTypes.prizeDraws ? <>
      <HeaderComponent
        props={{
          style: {
            paddingInline: DEFAULTGAP
          }
        }}
        title='Sorteios/Rifas'
        onAddClick={() => {
          setCurrent(undefined)
          setContentType(HomeTypes.prizeDraw)
        }}
        onRefreshClick={loadPrizeDraws}
      />

      {localPrizeDraws?.map(prizeDraw => <ResultSetItemComponent
        key={prizeDraw._id}
        showImg={true}
        imgProps={{
          src: `${dotEnvProductionState.payload?.APIURL}/img/${prizeDraw.imgUrl?.toString()}`,
          style: {
            borderRadius: 5
          }
        }}
        label={`${prizeDraw.title}`}
        obj={prizeDraw}
        onClick={(obj) => {
          setCurrent(obj)
          setContentType(HomeTypes.user)
        }} />)}

    </> : <PrizeDrawComponent
      onConfirm={() => {
        setContentType(HomeTypes.prizeDraws)
        loadPrizeDraws()
      }}
      onCancel={() => setContentType(HomeTypes.prizeDraws)}
      onDelete={() => {
        setContentType(HomeTypes.prizeDraws)
        loadPrizeDraws()
      }}
      data={current} />}
  </>
}