import { Button, Checkbox, Divider, FormControl, FormControlLabel, Radio, RadioGroup, styled, TextField } from "@mui/material"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ApiClientUtils } from "fwork.common.typescript/apiClient"
import { FormItemStyled, FormStyled, SelectImgComponent } from "fwork.react.apptemplate"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from "../../../apiClient/prizeDraws"
import { ConfirmDialogComponent } from "../../../common/components/dialogComponent/confirm"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { DEFAULTGAP } from "../../globals"
import { HomeTypes } from "../../types"
import { HeaderComponent } from "../miscelaneous/header"
import { PrizeDrawRankingComponent } from "../prizeDrawRanking"
import { PrizeDrawTicketsComponent } from "../prizeDrawTickets"
import { PrizeDrawAdditionalContainerComponent } from "./adicional"

const ImgWrapper = styled(FormItemStyled)(({ theme }) => ({
  width: '100% !important',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start'
  }
}))

export const PrizeDrawComponent = (props: {
  data?: IPrizeDraw,
  onConfirm?: () => void,
  onCancel?: () => void,
  onDelete?: () => void,
}) => {
  const [data, setData] = useState<IPrizeDraw>(props.data || {} as IPrizeDraw)
  const { enqueueSnackbar } = useSnackbar()
  const [homeType, setHomeType] = useState(HomeTypes.prizeDraw)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)

  let confirming = false
  const confirm = async () => {
    if (confirming) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'success' })
      return
    }
    confirming = true

    try {
      if ((data.limitCount || 0) <= 0) {
        enqueueSnackbar('Quantidade de cotas menor que zero.', {
          variant: 'error'
        })
        return
      }

      const apiResult = await (data._id ? ApiClientUtils.put({
        axios,
        apiUrl: `${dotEnvProductionState.payload?.APIURL}/prizedraws`,
        data,
      }) : ApiClientUtils.post({
        axios,
        apiUrl: `${dotEnvProductionState.payload?.APIURL}/prizedraws`,
        data
      }))

      if (apiResult.success) {
        enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' })
        if (props.onConfirm)
          props.onConfirm()
      }
      else {
        enqueueSnackbar(apiResult.msg, { variant: 'error' })
      }
    }
    finally {
      confirming = false
    }
  }

  let removing = false
  const remove = async () => {
    if (removing) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'error' })
      return
    }
    removing = true

    try {
      const apiResult = await new PrizeDrawsApiClient(axios).delete(data._id)

      if (apiResult.success) {
        enqueueSnackbar('Item excluído com sucesso', { variant: 'success' })
        if (props.onDelete)
          props.onDelete()
      }
      else {
        enqueueSnackbar(apiResult.msg, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
    finally {
      removing = false
    }
  }

  return <>
    <ConfirmDialogComponent
      title="Rifa"
      text="Deseja realmente excluir?"
      open={openDialog}
      onConfirm={() => {
        remove()
        setOpenDialog(false)
      }}
      onCancel={() => {
        setOpenDialog(false)
      }}
      onClose={() => setOpenDialog(false)}
    />

    {homeType == HomeTypes.prizeDraw ? <>
      <HeaderComponent
        props={{
          style: {
            paddingInline: DEFAULTGAP
          }
        }}
        title={`Sorteio/Rifa ${data?.title || ''}`}
        rightContent={props.data?._id ?
          <div>
            <Button onClick={() => setHomeType(HomeTypes.prizeDrawTickets)}>Movimentação</Button>
            <Button onClick={() => setHomeType(HomeTypes.prizeDrawRanking)}>Ranking</Button>
          </div> : <></>}

        onConfirmClick={confirm}
        onCancelClick={props.onCancel}
        onRemoveClick={() => setOpenDialog(true)}
      />

      <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <ImgWrapper>
          <SelectImgComponent
            id="prizeDrawImgPicker"
            alwaysShowButton={true}
            onSelectImg={(src: string) => {
              setData({
                ...data,
                imgUrl: src
              })
            }}
            imgProps={{
              src: data.imgUrl?.indexOf(';base64,') != -1 ? data.imgUrl?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${data.imgUrl?.toString()}`,
              style: {
                minWidth: 200,
                maxWidth: 570,
                width: '100%',
                height: 160,
                borderRadius: 8,
                objectFit: 'cover',
              }
            }} />
        </ImgWrapper>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          width: '100%'
        }}>
          <TextField label='Título'
            value={data?.title?.toString()}
            onChange={(e) => setData({
              ...data,
              title: e.target.value
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          width: '100%',
          maxHeight: 500,
          overflow: 'hidden'
        }}>
          <TextField multiline
            maxRows={10}
            label='Descrição'
            value={data?.description?.toString()}
            onChange={(e) => setData({
              ...data,
              description: e.target.value
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          width: '100%'
        }}>
          <TextField label='Url amigável'
            value={data?.friendlyUrl?.toString()}
            onChange={(e) => setData({
              ...data,
              friendlyUrl: e.target.value
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          width: '100%',
          maxHeight: 500,
          overflow: 'hidden'
        }}>
          <TextField multiline
            maxRows={10}
            label='Regulamento'
            value={data?.rules?.toString()}
            onChange={(e) => setData({
              ...data,
              rules: e.target.value
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField
            label='Preço'
            type='number'
            value={data?.price?.toString()}
            onChange={(e) => setData({
              ...data,
              price: Number(e.target.value)
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              inputFormat="DD/MM/YYYY HH:mm"
              label="Data do sorteio"
              renderInput={(params) => <TextField {...params} />}
              value={data?.limitDateTime || null}
              onChange={function (value: any, keyboardInputValue?: string | undefined): void {
                setData({
                  ...data,
                  limitDateTime: value ? value!.toISOString() : null
                })
              }} />
          </LocalizationProvider>
        </FormItemStyled>

        <div style={{ width: '100%' }} />

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField
            label='Quantidade de cotas para fechar a rifa'
            type='number'
            value={data?.limitCount?.toString()}
            onChange={(e) => setData({
              ...data,
              limitCount: Number(e.target.value)
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField
            label='Quantidade mínima de cotas por bilhete'
            type='number'
            value={data?.minCount?.toString()}
            onChange={(e) => setData({
              ...data,
              minCount: Number(e.target.value)
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField
            label='Quantidade máxima de cotas por bilhete'
            type='number'
            value={data?.maxCount?.toString()}
            onChange={(e) => setData({
              ...data,
              maxCount: Number(e.target.value)
            })}
          />
        </FormItemStyled>

        <div style={{ width: '100%' }}></div>
        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Destacar rifa' control={
            <Checkbox
              checked={data?.highLight as boolean}
              onChange={(e) => setData({
                ...data,
                highLight: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Permitir mudar os números do bilhete' control={
            <Checkbox
              checked={data?.allowRenewNumbers as boolean}
              onChange={(e) => setData({
                ...data,
                allowRenewNumbers: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        <Divider style={{
          marginLeft: DEFAULTGAP,
          marginTop: DEFAULTGAP,
          width: `calc(100% - ${DEFAULTGAP}px)`
        }} />

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Exibir botões de quantidades adicionais' control={
            <Checkbox
              checked={data?.showCountButtons as boolean}
              onChange={(e) => setData({
                ...data,
                showCountButtons: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Exibir números já vendidos' control={
            <Checkbox
              checked={data?.showSoldNumbers as boolean}
              onChange={(e) => setData({
                ...data,
                showSoldNumbers: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        <Divider style={{
          marginLeft: DEFAULTGAP,
          marginTop: DEFAULTGAP,
          width: `calc(100% - ${DEFAULTGAP}px)`
        }} />

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Exibir os números selecionados' control={
            <Checkbox
              checked={data?.showSelectedNumbers as boolean}
              onChange={(e) => setData({
                ...data,
                allowChooseNumbers: e.target.checked ? data.allowChooseNumbers : false,
                showNumbersOnlyPaidInSale: e.target.checked ? data.showNumbersOnlyPaidInSale : false,
                showSelectedNumbers: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControl>
            <RadioGroup row>
              <FormControlLabel disabled={!data.showSelectedNumbers} label='Sempre' control={<Radio
                checked={!data.showNumbersOnlyPaidInSale}
                onChange={(e) => setData({
                  ...data,
                  showNumbersOnlyPaidInSale: false
                })}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />} />
              <FormControlLabel disabled={!data.showSelectedNumbers} label='Apenas após o pagamento' control={<Radio
                checked={data.showNumbersOnlyPaidInSale}
                onChange={(e) => setData({
                  ...data,
                  showNumbersOnlyPaidInSale: true,
                  allowChooseNumbers: false,
                })}
                value="a"
                name="radio-buttons"
                inputProps={{ 'aria-label': 'A' }}
              />} />
            </RadioGroup>
          </FormControl>
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Permitir escolha dos números' control={
            <Checkbox
              checked={data?.allowChooseNumbers as boolean}
              onChange={(e) => setData({
                ...data,
                allowChooseNumbers: e.target.checked,
                showSelectedNumbers: e.target.checked || data.showSelectedNumbers,
                showNumbersOnlyPaidInSale: e.target.checked ? false : data.showNumbersOnlyPaidInSale
              })} />
          }></FormControlLabel>
        </FormItemStyled>

        {data._id ? <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          width: '100%'
        }}>
          <TextField label='Números sorteados (colocar os números separados por vírgula)'
            value={data?.drawnNumbers?.toString().replace('[', '').replace(']', '')}
            onChange={(e) => setData({
              ...data,
              drawnNumbers: e.target.value.split(',').map(v => Number(v))
            })}
          />
        </FormItemStyled> : <></>}

        {data._id ? <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
          justifyContent: 'flex-end',
          width: 'auto'
        }}>
          <FormControlLabel label='Sorteios encerrados' control={
            <Checkbox
              checked={data?.closed as boolean}
              onChange={(e) => setData({
                ...data,
                closed: e.target.checked
              })} />
          }></FormControlLabel>
        </FormItemStyled> : <></>}

      </FormStyled>
      {data?._id ? <PrizeDrawAdditionalContainerComponent
        prizeDrawOffPricesProps={{
          offPrices: data.offPrices || [],
          onChange: (offPrice) => {
            if (data) {
              const dataTemp = { ...data }
              dataTemp.offPrices = offPrice
              setData({
                ...dataTemp
              })
            }
          },
          onDelete: (index) => {
            let dataTemp: IPrizeDraw = {
              ...data,
              offPrices: [...data.offPrices || []]
            }
            dataTemp?.offPrices?.splice(index, 1)
            setData({
              ...dataTemp
            })
          }
        }}
        prizeDrawAwardedNumbersProps={{
          awardedNumbers: data.awardedNumbers || [],
          onChange: (awardedNumbers) => {
            if (data) {
              const dataTemp = { ...data }
              dataTemp.awardedNumbers = awardedNumbers
              setData({
                ...dataTemp
              })
            }
          },
          onDelete: (index) => {
            let dataTmp: IPrizeDraw = {
              ...data,
              awardedNumbers: [...data.awardedNumbers || []]
            }
            dataTmp?.awardedNumbers?.splice(index, 1)
            setData({
              ...dataTmp
            })
          }
        }} /> : <></>}

    </> : homeType == HomeTypes.prizeDrawTickets ?
      <PrizeDrawTicketsComponent prizeDraw={props.data} onBackClick={() => setHomeType(HomeTypes.prizeDraw)} /> :
      <PrizeDrawRankingComponent prizeDrawId={props.data?._id} onCloseClick={() => setHomeType(HomeTypes.prizeDraw)} />}
  </>
}