import { AnyAction, Reducer, ReducersMapObject } from 'redux'
import { IAuthenticationAction, IAuthenticationState } from './reducers/authenticationtest.slice'
import { IProgressState } from './reducers/globalProgress.slice';
import { IStylesState } from './reducers/styles.slice';
import authenticationReducer from './reducers/authenticationtest.slice'
import stylesReducer from './reducers/styles.slice'
import progressReducer from './reducers/globalProgress.slice'


export interface IRootState<UserType> extends Reducer<{
  authenticationState: IAuthenticationState<UserType>;
  stylesState: IStylesState;
  progressState: IProgressState;
}, AnyAction> {
  authenticationState: IAuthenticationState<UserType>;
  progressState: IProgressState
}

export interface IRootStateMapObjects<UserType> extends ReducersMapObject<{
  authenticationState: IAuthenticationState<UserType>;
  stylesState: IStylesState;
  progressState: IProgressState;
}, AnyAction> {
}

export interface IDefaultAction extends AnyAction {
  payload?: any,
  filter?: any,
  options?: {
    loading?: boolean
  },
}

export interface IDefaultState {
  payload?: any,
  filter?: any,
  options?: {
    loading?: boolean
  },
}

export function getRootState<UserType>() {
  const rootState: IRootState<UserType> | IRootStateMapObjects<UserType> = {
    authenticationState: authenticationReducer,
    stylesState: stylesReducer,
    progressState: progressReducer,
  }

  return rootState
}