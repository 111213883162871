import { styled } from "@mui/material"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import { PrizeDrawItemCard } from "../../../common/components/prizeDrawItemCard"
import { RoutesNames } from "../../../common/globals"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"

const PrizeDrawItemHighlightedWrapperStyled = styled('div')(({theme}) => {
  return {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#BDBDBD',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      padding: 20
    }
  }
})

export const PrizeDrawItemHighlightedComponent = (props: {
  highlightedPrizedraw: IPrizeDraw,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
}) => {
  const navigate = useNavigate()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  
  const onPrizeDrawCardClick = (prizeDraw: IPrizeDraw) => {
    navigate(RoutesNames.prizeDraw.replace(':id', prizeDraw._id))
  }

  return <PrizeDrawItemHighlightedWrapperStyled className="PrizeDrawItemHighlightedComponent" style={{
    ...props.props?.style
  }}>
    <div style={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundImage: `url(${dotEnvProductionState.payload?.APIURL}/img/${props.highlightedPrizedraw.imgUrl})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      opacity: .4,
    }}></div>
    <PrizeDrawItemCard prizeDraw={props.highlightedPrizedraw} style={{ zIndex: 10, width: '100%', maxWidth: 550, cursor: 'pointer' }} cutDescription={100} onClick={onPrizeDrawCardClick} />
  </PrizeDrawItemHighlightedWrapperStyled>
}