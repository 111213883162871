import { IconButton } from "@mui/material"
import { FlexGrowComponent } from "fwork.react.apptemplate"
import React from "react"
import { FaTrash } from "react-icons/fa"
import { IoIosAdd, IoIosCheckmark, IoIosClose, IoIosRefresh } from "react-icons/io"

export const HeaderComponent = (props: {
  title: string | React.ReactNode,
  rightContent?: React.ReactNode,
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  onAddClick?: () => void,
  onRefreshClick?: () => void,
  onConfirmClick?: () => void,
  onRemoveClick?: () => void,
  onCancelClick?: () => void,
}) => {
  return <div {...props.props} style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    ...props.props?.style
  }} >
    {typeof props.title == 'string' ? <h3>{props.title}</h3> : props.title}
    <FlexGrowComponent />

    {props.rightContent}

    <div>
      {props.onRefreshClick ? <IconButton onClick={props.onRefreshClick} style={{ height: 46, width: 46 }}>
        <IoIosRefresh size={25} />
      </IconButton> : <></>}

      {props.onAddClick ? <IconButton onClick={props.onAddClick}>
        <IoIosAdd size={30} />
      </IconButton> : <></>}

      {props.onConfirmClick ? <IconButton onClick={props.onConfirmClick}>
        <IoIosCheckmark size={30} />
      </IconButton> : <></>}

      {props.onRemoveClick ? <IconButton onClick={props.onRemoveClick} style={{ height: 46, width: 46 }}>
        <FaTrash size={15} />
      </IconButton> : <></>}

      {props.onCancelClick ? <IconButton onClick={props.onCancelClick}>
        <IoIosClose size={30} />
      </IconButton> : <></>}
    </div>
  </div>
}