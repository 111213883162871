import { MenuItem } from "@mui/material";
import React, { useState } from 'react';
import { FaFolderOpen } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RoutesNames } from '../../globals';
import { CustomerDataSmallComponent } from '../../../site/components/customerData/small';
import { DialogComponent } from '../dialogComponent';

export const AppBarMyTicketsMenuItemComponent = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const navigate = useNavigate()

  return <>
    <DialogComponent
      open={openDialog}
      onClose={() => setOpenDialog(false)}
    >
      <CustomerDataSmallComponent
        onConfirm={(data?: string) => {
          navigate(RoutesNames.userTickets, {
            state: {
              filter: data
            }
          })
        }}
        onCancel={() => setOpenDialog(false)}
      />
    </DialogComponent>

    <MenuItem
      sx={{ display: { xs: 'flex', md: 'none' }, gap: '10px !important' }}
      onClick={() => setOpenDialog(true)}
    ><FaFolderOpen color="#5A5A5A" /> Minhas Rifas
    </MenuItem>
  </>
}