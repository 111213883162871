import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IApiGetResult, IApiResult } from 'fwork.common.typescript/api'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import { IUser } from 'rifa.lib.common.ts/models'
import axios from '../../apiClient/axios'
import { UsersApiClient } from '../../apiClient/users'

export interface IUsersAction extends IDefaultAction {
  payload?: IUser[],
}

export interface IUsersState extends IDefaultState {
  payload?: IUser[],
}

const initState: IUsersState = {}

export const usersStateLoadFromApi = createAsyncThunk(
  'usersStateLoadFromApi',
  async (arg: {
    apiUrl?: string | undefined,
    onSuccess?: () => void,
    onError?: (msg?: string) => void
  }) => {
    const IapiResult: IApiResult<IApiGetResult<IUser[]>> = await new UsersApiClient(axios, arg.apiUrl).get()
    if (IapiResult.success) {
      if (arg.onSuccess)
        arg.onSuccess()
    }
    else if (arg.onError)
      arg.onError(IapiResult.msg)
    return IapiResult
  }
)

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState: initState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder.addCase(usersStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(usersStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data?.payload
    })
    builder.addCase(usersStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export default usersSlice.reducer