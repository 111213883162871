import { AxiosStatic } from 'axios'
import { BaseApiClient } from 'fwork.common.typescript/apiClient'
import { FilterQuery } from 'mongoose'
import { ISetting } from 'rifa.lib.common.ts/models'
import { store } from '../redux/store'

export class SettingsApiClient extends BaseApiClient<ISetting, any, FilterQuery<ISetting>, ISetting, ISetting> {
  constructor(axios: AxiosStatic, apiUrl?: string | undefined) {
    const dotEnvProductionState = store.getState().dotEnvProductionState
    const baseUrl = apiUrl || `${dotEnvProductionState.payload?.APIURL}/settings`
    super({
      apiUrl: baseUrl,
      axios
    })
  }
}