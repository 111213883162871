import { Button, IconButton, TextField } from "@mui/material";
import { ArrayUtils, NumberUtils } from "fwork.common.typescript";
import { ApiClientUtils } from "fwork.common.typescript/apiClient";
import { Form10x20Styled, FormItem10x20Styled } from "fwork.react.apptemplate";
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { CSSProperties, useEffect, useState } from "react";
import { SlMinus, SlPlus } from "react-icons/sl";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { ApiRoutesNames } from "rifa.lib.common.ts/api/routes";
import { ICustomer, IPrizeDraw, IPrizeDrawOffPrice, ITicket } from "rifa.lib.common.ts/models";
import axios from '../../../apiClient/axios';
import { TicketsApiClient } from "../../../apiClient/tickets";
import { DialogComponent } from "../../../common/components/dialogComponent";
import { PrizeDrawItemCard } from '../../../common/components/prizeDrawItemCard';
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice";
import { RootState } from "../../../redux/store";
import { CustomerDataComponent } from "../customerData";
import { DialogInputComponent } from "../dialogInput";
import { TicketWinnersComponent } from "../ticketWinners";
import { GridNumbersComponent } from "./grid";
import { PrizeDrawComponentCtrl } from "./index.ctrl";
import { PrizeDrawAdditionalInfoStyled, PrizeDrawItemCardWrapperStyled, PrizeDrawOffPricesInfoStyled, PrizeDrawOptionsItemStyled, PrizeDrawOptionsStyled, PrizeDrawOptionsWrapperStyled } from "./index.styled";
import { PrizeDrawOffPricesComponent } from "./offPrices";
import { RulesPrizeDrawComponent } from "./rules";
import { AwardedNumbersComponent } from "./awardedNumbers";
import { PrizeDrawBackGroundComponent } from "./background";

export const PrizeDrawComponent = (props: {
  style?: CSSProperties,
  prizeDraw: IPrizeDraw,
}) => {
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [showGetCustomerDataDialog, setShowGetCustomerDataDialog] = useState<boolean>(false)
  const [showRenewNumbersDialog, setShowRenewNumbersDialog] = useState<boolean>(false)
  const [winners, setWinners] = useState<ITicket[] | undefined>()
  const [selectedNumers, setSelectedNumbers] = useState<number[]>([])
  const [soldNumbers, setSoldNumbers] = useState<number[] | undefined>()

  // NUMEROS DISPONIVEIS CONSIDERANDO APENAS OS NUMEROS VENDIDOS EM OUTROS BILHETES
  const availableNumbersCount_consideringOnlySoldNumbers = (props.prizeDraw.limitCount || 0) - (soldNumbers?.length || 0)

  // NUMEROS DISPONIVEIS CONSIDERANDO OS NUMEROS VENDIDOS EM OUTROS BILHETES E OS QUE O USUARIO SELECIONOU
  const availableNumbersCount_consideringSoldAndSelectedNumbers = (props.prizeDraw.limitCount || 0) - (soldNumbers?.length || 0) - selectedNumers.length

  //NUMEROS PROMOCIONAIS CONSIDERANDO OS NUMEROS VENDIDOS EM OUTROS BILHETES E OS QUE O USUARIO SELECIONOU
  const offPricesAvailable = (props?.prizeDraw?.offPrices?.sort((a, b) => (a.from || 0) - (b.from || 0)).filter((offprice) =>
    ((offprice?.from || 0) <= (availableNumbersCount_consideringSoldAndSelectedNumbers))))

  const addRandomNumbers = (count: number) => {
    let selectedTmp = [...selectedNumers]
    let awardedNumbers = props.prizeDraw.awardedNumbers?.filter(an => an.validFromDateUnix > moment(Date.now()).unix())?.map(an => an.number)

    for (let i = 1; i <= count; i++) {
      let unavailableNumbers = [...selectedTmp, ...soldNumbers || [], ...awardedNumbers || []]
      const _number = PrizeDrawComponentCtrl.getRandomNumber(unavailableNumbers, props.prizeDraw)
      selectedTmp.push(_number)
    }
    setSelectedNumbers([
      ...selectedTmp
    ])
  }

  const removeRandomNumbers = (count: number) => {
    let tmp: number[] = [...selectedNumers]
    for (let i = 1; i <= count; i++) {
      const number = ArrayUtils.randomItem(tmp)
      tmp = tmp.filter(n => n != number)
    }
    setSelectedNumbers([
      ...tmp
    ])
  }

  useEffect(() => {
    loadWinners()
    loadSoldNumbers()
  }, [])

  const loadWinners = async () => {
    try {
      const response = await new TicketsApiClient(axios).getWinners({
        where: {
          ['prizeDraw._id']: props.prizeDraw._id
        }
      })

      if (response.success && response.data) {
        setWinners(response.data.payload)
      }
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  const loadSoldNumbers = async () => {
    try {
      const url = `${dotEnvProductionState.payload?.APIURL}${ApiRoutesNames.unavailableNumbers.replaceAll(':id', props.prizeDraw._id)}`
      const response = await axios.get(url)
      if (response.status == 200) {
        setSoldNumbers(response.data.payload)
      }
    }
    catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <DialogComponent
      open={showGetCustomerDataDialog}
      onClose={() => setShowGetCustomerDataDialog(false)}
    >
      <CustomerDataComponent
        onConfirm={(customer: ICustomer | undefined) => {
          if (customer)
            PrizeDrawComponentCtrl.post(customer, enqueueSnackbar, selectedNumers, props.prizeDraw, navigate)
        }}
        onCancel={() => {
          setShowGetCustomerDataDialog(false)
        }}
      />
    </DialogComponent>

    <DialogInputComponent
      title="Informe o código de segurança para trocar os números do bilhete"
      open={showRenewNumbersDialog}
      onClose={() => setShowRenewNumbersDialog(false)}
      onConfirm={(data) => {
        if (data)
          PrizeDrawComponentCtrl.postChangeNumbers(data, enqueueSnackbar, selectedNumers, navigate)
        else
          enqueueSnackbar('Código inválido')
      }}
      onCancel={() => setShowRenewNumbersDialog(false)}
    />

    <div className="PrizeDrawComponent" style={{
      position: 'relative',
      flexGrow: 1
    }}>
      {/* BACKGROUND */}
      {props.prizeDraw.imgUrl ? <PrizeDrawBackGroundComponent imgUrl={props.prizeDraw.imgUrl}/> : <></>}

      {/* MAIN CONTENT */}
      <Form10x20Styled
        className="PrizeDrawComponent-content"
        style={{
          maxWidth: 1024,
          margin: 'auto',
        }}>

        {/* IMAGEM E INFORMACOES PRINCIPAIS DA RIFA */}
        <PrizeDrawItemCardWrapperStyled className="PrizeDrawItemCardWrapperStyled" forceFullWidth={props.prizeDraw.closed || !(availableNumbersCount_consideringOnlySoldNumbers > 0)} style={{
          zIndex: 1
        }}>
          <PrizeDrawItemCard prizeDraw={props.prizeDraw} cutDescription={1000} />
        </PrizeDrawItemCardWrapperStyled>

        <PrizeDrawAdditionalInfoStyled className="PrizeDrawAdditionalInfoStyled" style={{
          zIndex: 1,
        }}>
          {props.prizeDraw?.rules ? <RulesPrizeDrawComponent
            detals={NumberUtils.getNumberStr(props.prizeDraw?.price || 0)} dateTime={props.prizeDraw?.limitDateTime} rules={props.prizeDraw?.rules} /> :
            <div style={{ padding: 15 }}>
              <div>{`Por apenas: R$ ${NumberUtils.getNumberStr(props.prizeDraw.price || 0)}🔥`}</div>
              {props.prizeDraw?.limitDateTime ? <div>{`Sorteio: ${moment(props.prizeDraw.limitDateTime as string).format('DD/MM/YYYY HH:mm')}`}</div> : <></>}
            </div>
          }
        </PrizeDrawAdditionalInfoStyled>

        {/* GRADE DE COTAS PREMIADAS*/}
        {props.prizeDraw?.awardedNumbers &&
          <AwardedNumbersComponent awardedNumbers={props.prizeDraw?.awardedNumbers} winners={winners} />}

        {!props.prizeDraw.closed && (availableNumbersCount_consideringOnlySoldNumbers > 0) ? 
        <PrizeDrawOptionsWrapperStyled className="PrizeDrawOptionsWrapperStyled">

          {availableNumbersCount_consideringSoldAndSelectedNumbers >= 1 ? <PrizeDrawOptionsStyled className="PrizeDrawOptionsStyled">

            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 1 * (props.prizeDraw.minCount ?? 1) ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(1 * (props.prizeDraw.minCount ?? 1))}>+ {1 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}
            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 2 * (props.prizeDraw.minCount ?? 1) && props.prizeDraw.showCountButtons ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(2 * (props.prizeDraw.minCount ?? 1))}>+ {2 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}
            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 5 * (props.prizeDraw.minCount ?? 1) && props.prizeDraw.showCountButtons ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(5 * (props.prizeDraw.minCount ?? 1))}>+ {5 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}
            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 10 * (props.prizeDraw.minCount ?? 1) && props.prizeDraw.showCountButtons ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(10 * (props.prizeDraw.minCount ?? 1))}>+ {10 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}
            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 50 * (props.prizeDraw.minCount ?? 1) && props.prizeDraw.showCountButtons ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(50 * (props.prizeDraw.minCount ?? 1))}>+ {50 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}
            {availableNumbersCount_consideringSoldAndSelectedNumbers >= 100 * (props.prizeDraw.minCount ?? 1) && props.prizeDraw.showCountButtons ? <PrizeDrawOptionsItemStyled onClick={() => addRandomNumbers(100 * (props.prizeDraw.minCount ?? 1))}>+ {100 * (props.prizeDraw.minCount ?? 1)}</PrizeDrawOptionsItemStyled> : <></>}

            {/* GRADE DE PROMOÇÕES */}
            {offPricesAvailable?.map((offprices) => {
              if (offprices.initDateTime && offprices.endDatetime) {
                let nowLocalIso = moment().format()
                let dtIniLocalIso = moment(offprices.initDateTime).toISOString(true)
                let dtFinLocalIso = moment(offprices.endDatetime).toISOString(true)

                if (dtIniLocalIso <= nowLocalIso && dtFinLocalIso >= nowLocalIso) {
                  return <PrizeDrawOptionsItemStyled style={{ backgroundColor: '#3CB371', }}
                    onClick={() => addRandomNumbers(offprices?.from || 0)}>+ {offprices?.from}</PrizeDrawOptionsItemStyled>
                }
              }
            })}
          </PrizeDrawOptionsStyled> : <></>}

          <TextField
            style={{
              background: 'white',
              borderRadius: 5,
            }}
            inputProps={{
              style: {
                textAlign: 'center',
              }
            }}
            InputProps={{
              placeholder: 'Entre a quantidade',
              value: selectedNumers.length,
              startAdornment: <IconButton onClick={(e) => {
                removeRandomNumbers(1)
              }} disabled={!selectedNumers.length}>
                <SlMinus />
              </IconButton>,
              endAdornment: <IconButton onClick={() => {
                addRandomNumbers(1)
              }} disabled={(props.prizeDraw.limitCount || 0) - (selectedNumers?.length || 0) == 0}>
                <SlPlus />
              </IconButton>
            }}
          />

          <Button
            style={{
              background: 'grey',
              color: 'white'
            }}
            onClick={() => setSelectedNumbers([])}
          >Limpar</Button>

          <Button
            onClick={() => {
              if (!PrizeDrawComponentCtrl.validateNumbers(selectedNumers, enqueueSnackbar)) return
              if (!PrizeDrawComponentCtrl.validateCount(selectedNumers, props.prizeDraw, enqueueSnackbar)) return
              setShowGetCustomerDataDialog(true)
            }}
            variant="contained"
          >Comprar</Button>
        </PrizeDrawOptionsWrapperStyled> : <></>}

        {props.prizeDraw.offPrices ? props.prizeDraw.offPrices.filter(offPrice => {
          var minDate = new Date(-8640000000000000).toISOString();
          var maxDate = new Date(8640000000000000).toISOString();
          var now = new Date().toISOString();

          return ((offPrice.initDateTime || minDate) <= now) && ((offPrice.endDatetime || maxDate) >= now)
        }).sort((a: IPrizeDrawOffPrice, b: IPrizeDrawOffPrice) => (a.from || 0) - (b.from || 0)).map((offPrice) => {
          return <>
            <PrizeDrawOffPricesInfoStyled style={{ zIndex: 1, }}>
              <PrizeDrawOffPricesComponent key={offPrice._id} from={offPrice.from} price={offPrice.price} />
            </PrizeDrawOffPricesInfoStyled></>
        }) : <></>}

        {/* GRADE DOS NUMEROS JA VENDIDOS */}
        {props.prizeDraw.showSoldNumbers && !props.prizeDraw.closed && soldNumbers?.length ? <FormItem10x20Styled style={{
          width: '100%',
          order: 6
        }}>
          <label style={{ marginBottom: 10 }}>{soldNumbers?.length} número{soldNumbers?.length > 1 ? 's' : ''} indisponíve{soldNumbers?.length > 1 ? 'is' : 'l'}</label>
          <GridNumbersComponent
            props={{ className: 'GridNumbersComponent-sold' }}
            numbers={soldNumbers.sort()}
            disableNumbers={soldNumbers}
            rangeCount={20}
            initSelected={selectedNumers.sort()}
            onChange={(_selected: number[]) => {
              setSelectedNumbers([..._selected])
            }}
          />
        </FormItem10x20Styled> : <></>}

        {/* GRADE DOS NUMEROS SELECIONADOS */}
        {props.prizeDraw.showSelectedNumbers && !props.prizeDraw.showNumbersOnlyPaidInSale && !props.prizeDraw.closed && selectedNumers.length ? <FormItem10x20Styled style={{
          width: '100%',
          order: 7
        }}>
          <label style={{ marginBottom: 10 }}>{selectedNumers.length} número{selectedNumers.length > 1 ? 's' : ''} selecionado{selectedNumers.length > 1 ? 's' : ''}</label>
          <GridNumbersComponent
            props={{ className: 'GridNumbersComponent-selected', }}
            numbers={selectedNumers}
            rangeCount={20}
            initSelected={selectedNumers.sort()}
            onChange={(_selected: number[]) => {
              setSelectedNumbers([..._selected])
            }}
          />
        </FormItem10x20Styled> : <></>}

        {!(availableNumbersCount_consideringSoldAndSelectedNumbers > 0) ? <FormItem10x20Styled style={{
          width: '100%',
          order: 8,
        }}>
          <label style={{ marginBottom: 10 }}>Não há mais números disponíveis</label>
        </FormItem10x20Styled> : <></>}

        {/* GRADE PRINCIPAL */}
        {props.prizeDraw.allowChooseNumbers && !props.prizeDraw.closed && (availableNumbersCount_consideringOnlySoldNumbers > 0) ? <FormItem10x20Styled style={{
          width: '100%',
          order: 9
        }}>
          {availableNumbersCount_consideringSoldAndSelectedNumbers ?
            <label style={{ marginBottom: 10 }}>{availableNumbersCount_consideringSoldAndSelectedNumbers} número{availableNumbersCount_consideringSoldAndSelectedNumbers > 1 ? 's' : ''} disponíve{availableNumbersCount_consideringSoldAndSelectedNumbers > 1 ? 'is' : 'l'}</label> :
            <></>}
          <GridNumbersComponent
            props={{ className: 'GridNumbersComponent-main' }}
            disableNumbers={soldNumbers}
            from={1}
            to={props.prizeDraw.limitCount || 1}
            initSelected={selectedNumers}
            onChange={(_selected: number[]) => {
              setSelectedNumbers([..._selected])
            }}
          />

        </FormItem10x20Styled> : <></>}

        {props.prizeDraw?.allowRenewNumbers && !props.prizeDraw.closed ? <FormItem10x20Styled fullWidth style={{
          order: 10,
          alignItems: 'flex-end'
        }}>
          <Button
            onClick={() => {
              if (!PrizeDrawComponentCtrl.validateNumbers(selectedNumers, enqueueSnackbar)) return
              setShowRenewNumbersDialog(true)
            }}
            variant="contained"
          >Trocar os números de um bilhete</Button>
        </FormItem10x20Styled> : <></>}
      </Form10x20Styled>

      {/* WINNERS */}
      {winners?.length ? <TicketWinnersComponent
        hidePrizeDrawTitle
        props={{
          style: {
            margin: 'auto',
            maxWidth: 1024,
            width: '100%',
            zIndex: 1,
            order: 3
          }
        }} winners={winners} /> : <></>}
    </div >
  </>
}