import { BaseApiClient } from 'fwork.common.typescript/apiClient'
import { IUser } from 'rifa.lib.common.ts/models'
import { FilterQuery } from 'mongoose'
import { AxiosStatic } from 'axios'
import { store } from '../redux/store'

export class UsersApiClient extends BaseApiClient<IUser, any, FilterQuery<IUser>, IUser, IUser> {
  constructor(axios: AxiosStatic, apiUrl?: string | undefined) {
    const dotEnvProductionState = store.getState().dotEnvProductionState
    const baseUrl = apiUrl || `${dotEnvProductionState.payload?.APIURL}/users`
    super({
      apiUrl: baseUrl,
      axios
    })
  }
}