import { NumberUtils } from "fwork.common.typescript"
import moment from "moment"

export const FormatTextToPrint = (props: {
  userName?: string,
  email?: string,
  cpf?: string,
  phone?: string,
  prizeDrawTitle?: string,
  ticketValue?: number,
  value?: string | number,
  numbers?: string,
  dateTime?: string,
  status?: string | undefined,
}) => {
  let result = `
    Cliente: ${props.userName}
    Email: ${props.email}
    CPF: ${props.cpf}
    Telefone: ${props.phone}
    Rifa: ${props.prizeDrawTitle}
    Numeros escolhidos: ${props.numbers}
    Total de numeros: ${props.numbers?.length}
    Valor individual: ${NumberUtils.getNumberStr(props.ticketValue as number)}
    Valor da compra: ${NumberUtils.getNumberStr(props.value as number)} 
    Status: ${props.status}
    Data do bilhete: ${moment(props.dateTime as string).format('DD/MM/YYYY HH:mm')}

    Obrigado pela preferencia.



    
    .
`
  return encodeURIComponent(result)
}