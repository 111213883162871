import { Button, InputAdornment } from "@mui/material";
import TextField from '@mui/material/TextField';
import { CommonUtils } from "fwork.common.typescript";
import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";

export const CustomerDataSmallComponent = (props: {
  onConfirm?: (data?: string) => void,
  onCancel?: () => void
}) => {

  const [data, setData] = useState<string | undefined>()

  const isValidData = (): boolean => {
    if (data) {
      const isValidPhone = CommonUtils.isValidPhone(data)
      const isValidEmail = CommonUtils.isValidEmail(data)
      const isValidCPF = CommonUtils.isValidCPF(data)

      return isValidPhone || isValidEmail || isValidCPF
    }
    else return false
  }

  const validData = isValidData()

  return <>
    <span>Preencha os dados abaixo para consultar seus números:</span>

    <TextField
      error={data && !validData ? true : false}
      helperText={data && !validData ? 'Telefone/Email/CPF inválido' : ''}
      value={data}
      fullWidth
      variant="outlined"
      placeholder="Telefone/Email/CPF"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaPhone />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setData(e.target.value)}
    />

    <Button
      fullWidth
      variant="contained"
      onClick={() => {
        if (props.onConfirm) props.onConfirm(data)
      }}
    >
      Confirmar
    </Button>

    <Button
      fullWidth
      variant="text"
      onClick={() => {
        if (props.onCancel) props.onCancel()
      }}
    >
      Voltar
    </Button>
  </>
}