import { BaseApiClient } from 'fwork.common.typescript/apiClient'
import { FilterQuery } from 'mongoose'
import { IPrizeDraw } from 'rifa.lib.common.ts/models'
import { AxiosStatic } from 'axios'
import { store } from '../redux/store'

export class PrizeDrawsApiClient extends BaseApiClient<IPrizeDraw, any, FilterQuery<IPrizeDraw>, IPrizeDraw, IPrizeDraw> {
  constructor(axios: AxiosStatic, apiUrl?: string | undefined) {
    const dotEnvProductionState = store.getState().dotEnvProductionState
    const baseUrl = apiUrl || `${dotEnvProductionState.payload?.APIURL}/prizedraws`
    super({
      apiUrl: baseUrl,
      axios
    })
  }
}