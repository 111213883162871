import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IPrizeDraw } from 'rifa.lib.common.ts/models';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import { RoutesNames } from '../../globals';
import { IPrizeDrawsState } from '../../../redux/reducers/prizeDraws.slice';
import { store } from '../../../redux/store';

// ICONS
import { IoMdHome as HomeIcon } from 'react-icons/io';
import { MdOutlineArrowForwardIos as DividerIcon } from 'react-icons/md';
import { styled } from '@mui/material';

// STYLE
const breadcrumbAlignItem: React.CSSProperties = { display: 'flex', alignSelf: 'center' }
const breadcrumbLinkDecoration: React.CSSProperties = { textDecoration: 'none', color: '#166CC8' }
const colorUnlink: React.CSSProperties = { color: '#606060' }

const BreadcrumbWrapperStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  paddingBlock: 10,
  paddingInline: 10,
  boxSizing: 'border-box',
  [theme.breakpoints.up('sm')]: {
    paddingInline: 20,
  }
}))

export const BreadcrumbComponent = (props: {
  props?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
}) => {
  const unLink = [
    '/prizedraw',
    '/ticket'
  ]

  const location = useLocation()
  const breadcrumbs = useReactRouterBreadcrumbs([
    {
      path: RoutesNames.home,
      breadcrumb: 'Home'
    },
    {
      path: '/prizedraw',
      breadcrumb: 'Rifas',
      children: [{
        path: RoutesNames.prizeDraw,
        breadcrumb: ({ match }) => {
          const prizedrawState: IPrizeDrawsState = store.getState().prizeDrawsState
          const prizeDraw: IPrizeDraw | undefined = prizedrawState.payload?.filter(p => p._id == match.params.id)[0]
          return <span>{prizeDraw?.title}</span>
        }
      }]
    },
    {
      path: '/ticket',
      breadcrumb: 'Tickets',
      children: [{
        path: RoutesNames.ticket,
        breadcrumb: ({ match }) => {
          return <span>{match.params.id}</span>
        }
      }]
    },
    {
      path: RoutesNames.userRegister,
      breadcrumb: 'Cadastro de Usuário'
    },
    {
      path: RoutesNames.userProfile,
      breadcrumb: 'Perfil'
    },
    {
      path: RoutesNames.userForgotPwd,
      breadcrumb: 'Recuperar Senha'
    },
    {
      path: RoutesNames.userChangePwd,
      breadcrumb: 'Alterar Senha'
    },
    {
      path: RoutesNames.userTickets,
      breadcrumb: 'Rifas'
    },
    {
      path: RoutesNames.login,
      breadcrumb: 'Entrar'
    },
    {
      path: RoutesNames.admin,
      breadcrumb: 'admin'
    },
    {
      path: RoutesNames.useThermes,
      breadcrumb: 'Termos de Uso'
    },
    {
      path: RoutesNames.privacyPolicy,
      breadcrumb: 'Política de Privacidade'
    }
  ]);

  const checkArrays = (arrayA: any[], arrayB: any[]) => arrayA.some(r => arrayB.indexOf(r) >= 0)

  return <BreadcrumbWrapperStyled {...props.props}>
    {breadcrumbs.map((args, index) => {
      return <React.Fragment key={index}>
        {/* ICON HOME */}
        {args.match.pathname == RoutesNames.home ? <Link style={breadcrumbAlignItem} to={args.match.pathname}>
          <HomeIcon style={{ ...breadcrumbAlignItem, color: '#166CC8', marginRight: 5 }} /> </Link> : null}
        <span style={{ ...breadcrumbAlignItem }}>
          {/* ARROWICON DIVIDER */}
          {args.match.pathname != RoutesNames.home ? <DividerIcon style={{ ...breadcrumbAlignItem, margin: '0px 5px 0px 5px', color: '#505050' }} /> : null}
          {/* PATH/ROUTES NAMES */}
          {location.pathname == args.match.pathname ? <div style={colorUnlink}> {args.breadcrumb}</div> :
            checkArrays([args.match.pathname], unLink) ? <div style={colorUnlink}>{args.match.route?.breadcrumb as string}</div> :
              <Link style={breadcrumbLinkDecoration} to={args.match.pathname}>{args.breadcrumb}</Link>}</span>
      </React.Fragment>
    })}
  </BreadcrumbWrapperStyled>
}