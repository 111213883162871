import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IApiGetResult, IApiResult } from 'fwork.common.typescript/api'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import { FilterQuery } from 'mongoose'
import { ITicket } from 'rifa.lib.common.ts/models'
import axios from '../../apiClient/axios'
import { TicketsApiClient } from '../../apiClient/tickets'

export interface ITicketsAction extends IDefaultAction {
  payload?: ITicket[],
}

export interface ITicketsState extends IDefaultState {
  payload?: ITicket[],
}

const initState: ITicketsState = {}

export const ticketsStateLoadFromApi = createAsyncThunk(
  'ticketsStateLoadFromApi',
  async (arg: {
    apiUrl?: string | undefined,
    where?: FilterQuery<ITicket>,
    onSuccess?: () => void,
    onError?: (msg?: string) => void
  }) => {
    const IapiResult: IApiResult<IApiGetResult<ITicket[]>> = await new TicketsApiClient(axios, arg.apiUrl).get({
      where: arg.where
    })
    
    if (IapiResult.success) {
      if (arg.onSuccess)
        arg.onSuccess()
    }
    else if (arg.onError)
      arg.onError(IapiResult.msg)
    return IapiResult
  }
)

export const ticketsStateLoadWinnersFromApi = createAsyncThunk(
  'ticketsStateLoadWinnersFromApi',
  async (arg: {
    apiUrl?: string | undefined,
    where?: FilterQuery<ITicket>,
    onSuccess?: () => void,
    onError?: (msg?: string) => void
  }) => {
    const IapiResult: IApiResult<IApiGetResult<ITicket[]>> = await new TicketsApiClient(axios, arg.apiUrl).get({
      where: arg.where
    })
    
    if (IapiResult.success) {
      if (arg.onSuccess)
        arg.onSuccess()
    }
    else if (arg.onError)
      arg.onError(IapiResult.msg)
    return IapiResult
  }
)

export const ticketsSlice = createSlice({
  name: 'ticketsSlice',
  initialState: initState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    // ticketsStateLoadFromApi
    builder.addCase(ticketsStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(ticketsStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data?.payload
    })
    builder.addCase(ticketsStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    }),
    
    // ticketsStateLoadWinnersFromApi
    builder.addCase(ticketsStateLoadWinnersFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(ticketsStateLoadWinnersFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data?.payload
    })
    builder.addCase(ticketsStateLoadWinnersFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export default ticketsSlice.reducer