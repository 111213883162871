import { Button, styled } from "@mui/material"
import { WebUtils } from "fwork.common.typescript"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ISettingsState } from "../../../redux/reducers/settings.slice"
import { RootState } from "../../../redux/store"
import { HtmlRawViewComponent } from "../htmlRawView"

const CookiePolicyComponentWrapperStyled = styled('div')(({theme}) => {
  return {
    boxSizing: 'border-box',
    zIndex: 1000,
    background: '#ddeffe',
    color: '#515151',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    boxShadow: 'rgb(0 0 0) 0px 28px 50px 5px',
    padding: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: 10,
  }
})

export const CookiePolicyComponent = () => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)
  const [confirmedCookiePolicy, setConfirmedCookiePolicy] = useState(WebUtils.getCookie('@cookiepolicy') ? true : false)

  const confirmPolicyCookie = () => {
    WebUtils.setCookie('@cookiepolicy', '1', 1)
    setConfirmedCookiePolicy(true)
  }

  let cookiePolicyData
  if (settingsState.payload?.length)
    if (settingsState.payload[0].cookiePolicy)
      if (!([null, ''].indexOf(settingsState.payload[0].cookiePolicy.toString()) != -1))
        cookiePolicyData = settingsState.payload[0].cookiePolicy

  return cookiePolicyData && !confirmedCookiePolicy ?
    <CookiePolicyComponentWrapperStyled>
      <HtmlRawViewComponent props={{
        style: {
          padding: 0
        }
      }} content={cookiePolicyData} />

      <Button
        variant="contained"
        onClick={confirmPolicyCookie}
        style={{
          paddingInline: '25px'
        }}> PERMITIR TODOS OS COOKIES
      </Button>
    </CookiePolicyComponentWrapperStyled> : <></>
}