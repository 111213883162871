import CloseIcon from '@mui/icons-material/Close'
import { Autocomplete, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import * as locales from '@mui/material/locale'
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
import styled from '@mui/material/styles/styled'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { PaymentTransactionsStatusDescriptions } from '@sl7/payment.lib.common.ts/models'
import { ArrayUtils, NumberUtils } from 'fwork.common.typescript'
import { ApiClientUtils } from 'fwork.common.typescript/apiClient'
import { FormItemStyled, FormStyled, IAuthenticationState, ImageComponent } from 'fwork.react.apptemplate'
import moment from 'moment'
import { FilterQuery } from 'mongoose'
import { useSnackbar } from 'notistack'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { IoIosStats } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ApiRoutesNames } from 'rifa.lib.common.ts/api/routes'
import { IPrizeDraw, ITicket, IUser, PaymentStatus, PaymentTypes, UserTypes } from 'rifa.lib.common.ts/models'
import axios from '../../../apiClient/axios'
import { PrizeDrawsApiClient } from '../../../apiClient/prizeDraws'
import { TicketsApiClient } from '../../../apiClient/tickets'
import { ConfirmDialogComponent } from '../../../common/components/dialogComponent/confirm'
import { RoutesNames } from '../../../common/globals'
import { IDotEnvProductionState } from '../../../redux/reducers/dotenvProduction.slice'
import { toggleLoading } from '../../../redux/reducers/loadingBar.slice'
import { RootState } from '../../../redux/store'
import { DEFAULTGAP } from '../../globals'
import { HeaderComponent } from '../miscelaneous/header'
import { MoreIconComponent } from './moreIcon'

type SupportedLocales = keyof typeof locales

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 6,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

enum MovementTypes {
  all,
  showOnlyWinners,
  hideCanceled,
}

export const PrizeDrawsMovementComponent = () => {
  const [page, setPage] = useState(0)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [renewedTicketId, setRenewedTicketId] = useState<string | undefined>()
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [tickets, setTickets] = useState<ITicket[] | undefined>()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)

  const [searchCustomer, setSearchText] = useState<string | undefined>()
  const [searchUser, setSearchUser] = useState<string | undefined>
    (authenticationState?.payload?.user?.userType != UserTypes.admin ? authenticationState?.payload?.user?.name : undefined)

  const [initialDate, setInitialDate] = useState<string | undefined>(moment('00:00', 'HH:mm').toISOString())
  const [finalDate, setFinalDate] = useState<string | undefined>(moment('23:59', 'HH:mm').toISOString())
  const [movementType, setMovementType] = useState(MovementTypes.hideCanceled)
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>()
  const [prizeDraws, setPrizeDraws] = useState<IPrizeDraw[] | undefined>()
  const [selectedPrizedraw, setSelectedPrizedraw] = useState<IPrizeDraw | undefined>()
  const [searchTicketNumber, setSearchTicketNumber] = useState<number | undefined>()

  //TablePagination
  const [locale] = useState<SupportedLocales>('ptBR')
  const theme = useTheme()
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme],
  )

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  useEffect(() => {
    loadTickets()
    loadPrizeDraws()
  }, [])

  const loadTickets = async () => {
    try {
      dispatch(toggleLoading())
      let where: FilterQuery<ITicket> = {
        $and: []
      }

      if (searchCustomer) {
        where = {
          ...where,
          "$or": [
            { "customer.CPF": { $regex: searchCustomer } },
            { "customer.phoneNumber": { $regex: searchCustomer } },
            { "customer.EMAIL": { $regex: searchCustomer, $options: 'i' } },
          ]
        }
      }

      if (selectedPrizedraw?._id) {
        where.$and?.push({
          'prizeDraw._id': {
            type: 'objectid',
            value: selectedPrizedraw?._id
          }
        })
      }

      if (searchTicketNumber) {
        where.$and?.push({ numbers: { "$in": [searchTicketNumber] } })
      }

      if (searchUser) {
        where.$and?.push({ 'USER.name': { $regex: searchUser, $options: 'i' } })
      }

      if (initialDate) {
        where.$and?.push({ dateTime: { "$gte": initialDate } })
      }

      if (finalDate) {
        where.$and?.push({ dateTime: { "$lte": finalDate } })
      }

      if (movementType == MovementTypes.hideCanceled) {
        where.$and?.push({ canceled: { "$ne": true } })
      }

      if (paymentStatus != null && paymentStatus != PaymentStatus.undefined) {
        where.$and?.push({ 'payments.status': paymentStatus });
      }

      const response = movementType == MovementTypes.showOnlyWinners ?
        await new TicketsApiClient(axios).getWinners({
          where
        }) :
        await new TicketsApiClient(axios).get({
          where,
          nested: 'customer,prizeDraw,USER'
        })

      if (response.success) {
        setTickets(response.data?.payload)
      } else {
        enqueueSnackbar('Erro ao consultar', { variant: 'error' })
      }

    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    } finally {
      dispatch(toggleLoading())
    }
  }

  const loadPrizeDraws = async () => {
    try {
      dispatch(toggleLoading())
      const response = await new PrizeDrawsApiClient(axios).get({})
      if (response.success) {
        setPrizeDraws(response?.data?.payload)
      } else {
        enqueueSnackbar('Erro ao consultar', { variant: 'error' })
      }

      return response.data?.payload
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    } finally {
      dispatch(toggleLoading())
    }
  }

  let posting = false
  const renewNumbers = async (ticketId?: string) => {
    if (posting) {
      enqueueSnackbar('Operação em andamento. Aguarde...', { variant: 'info' })
      return
    }
    posting = true

    try {
      dispatch(toggleLoading())
      const response = await axios.post(`${dotEnvProductionState.payload?.APIURL}${ApiRoutesNames.ticketsRenewNumbers.replace(':id', ticketId as string)}`)

      if (response.status === 200) {
        enqueueSnackbar(`Código de confirmação: ${response?.data}`, {
          variant: 'success',
          persist: true,
          action: (key) => (
            <IconButton onClick={() => closeSnackbar(key)} style={{
              cursor: 'pointer',
              color: '#fefefe'
            }}>
              <CloseIcon />
            </IconButton>
          ),
        })
      }
      else {
        enqueueSnackbar(
          "Não foi possível gerar novos números",
          { variant: "error" }
        )
      }
    }
    catch (error) {
      enqueueSnackbar(`Erro ao gerar novos números. ${error}`, { variant: 'error' })
    }
    finally {
      dispatch(toggleLoading())
      posting = false
    }
  }

  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    height: '100%',
    paddingBottom: 11
  }}>

    <ConfirmDialogComponent
      title="Regerar números"
      text="Deseja realmente gerar novos números?"
      open={openDialog}
      onConfirm={() => {
        renewNumbers(renewedTicketId)
        setOpenDialog(false)
      }}
      onCancel={() => {
        setOpenDialog(false)
      }}
      onClose={() => setOpenDialog(false)}
    />
    <HeaderComponent
      props={{
        style: {
          paddingInline: DEFAULTGAP
        }
      }}
      title='Movimentação'
      rightContent={
        <IconButton onClick={() => {
          navigate(RoutesNames.adminDashboard)
        }}>
          <IoIosStats size={25} />
        </IconButton>

      }
      onRefreshClick={loadTickets}
    />
    <FormStyled gap={DEFAULTGAP}>
      {authenticationState?.payload?.user?.userType != UserTypes.seller ?
        <FormItemStyled>
          <TextField
            placeholder='Nome do usuário'
            variant='outlined'
            defaultValue={authenticationState?.payload?.user?.userType != UserTypes.admin ? authenticationState?.payload?.user?.name : undefined}
            onChange={(e) => setSearchUser(e.target.value)}
          />
        </FormItemStyled> : <></>}

      <FormItemStyled>
        <TextField
          placeholder='Email/Telefone/CPF do cliente'
          variant='outlined'
          onChange={(e) => setSearchText(e.target.value)}
        />
      </FormItemStyled>

      <FormItemStyled>
        <TextField
          placeholder='Número escolhido pelo cliente'
          variant='outlined'
          onChange={(e) => setSearchTicketNumber(Number(e.target.value))}
        />
      </FormItemStyled>

      <FormItemStyled>
        <Autocomplete
          inputValue={selectedPrizedraw?.title || ''}
          onInputChange={(event: any, newValue: any) => {
            const tmp = prizeDraws?.filter(p => p.title == newValue)[0]
            setSelectedPrizedraw(tmp)
          }}
          options={prizeDraws?.length ? prizeDraws?.map((options) => options.title) : []}
          renderInput={(params) => <TextField {...params} label="Sorteio" />}
        />
      </FormItemStyled>

      <FormItemStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            ampm={false}
            inputFormat='DD/MM/YYYY HH:mm'
            label="Data inicial"
            renderInput={(params) => <TextField {...params} />}
            value={initialDate}
            onChange={function (value: any, keyboardInputValue?: string | undefined): void {
              setInitialDate(moment(value).toISOString())
            }}
          />
        </LocalizationProvider>
      </FormItemStyled>

      <FormItemStyled>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DateTimePicker
            ampm={false}
            inputFormat='DD/MM/YYYY HH:mm'
            label="Data final"
            renderInput={(params) => <TextField {...params} />}
            value={finalDate}
            onChange={function (value: any, keyboardInputValue?: string | undefined): void {
              setFinalDate(moment(value).toISOString())
            }}
          />
        </LocalizationProvider>
      </FormItemStyled>

      <FormItemStyled>
        <FormControl>
          <InputLabel id="select-label">Status do pagamento</InputLabel>
          <Select
            labelId="select-label"
            id="simple-select"
            value={paymentStatus}
            label="Status"
            onChange={(event) => {
              setPaymentStatus(Number(event.target.value))
            }}>
            <MenuItem value={PaymentStatus.undefined}>Exibir todos</MenuItem>
            <MenuItem value={PaymentStatus.paid}>Pago</MenuItem>
            <MenuItem value={PaymentStatus.waitingPayment}>Aguardando pagamento</MenuItem>
            <MenuItem value={PaymentStatus.waitingConfirmation}>Aguardando confirmação</MenuItem>
            <MenuItem value={PaymentStatus.canceled}>Cancelado</MenuItem>
          </Select>
        </FormControl>
      </FormItemStyled>

      <div style={{ width: '100%' }} />

      {/* <FormItemStyled style={{
        width: '100%',
      }}>
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={movementType}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMovementType(Number((event.target as HTMLInputElement).value))
          }}
        >
          <FormControlLabel value={MovementTypes.all} control={<Radio />} label="Exibir todos" />
          <FormControlLabel value={MovementTypes.hideCanceled} control={<Radio />} label="Ocultar cancelados" />
          <FormControlLabel value={MovementTypes.showOnlyWinners} control={<Radio />} label="Exibir apenas ganhadores" />
        </RadioGroup>
      </FormItemStyled> */}

      <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2} style={{
        justifyContent: 'flex-end',
        width: 'auto'
      }}>
        <FormControlLabel label='Exibir apenas ganhadores' control={
          <Checkbox
            checked={(movementType == MovementTypes.showOnlyWinners) as boolean}
            onChange={(e) => setMovementType(e.target.checked ? MovementTypes.showOnlyWinners : MovementTypes.all)} />
        }></FormControlLabel>
      </FormItemStyled>

    </FormStyled>

    <div className='table-and-pagination-wrapper' style={{ padding: DEFAULTGAP, maxWidth: 'calc(100% - 10px)' }}>
      <div className='table-wrapper' style={{ boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 3px', width: '100%', overflowX: 'auto', borderRadius: 5, overflow: 'auto' }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell key={1} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Rifa</StyledTableCell>
              <StyledTableCell key={2} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Título</StyledTableCell>
              <StyledTableCell key={3} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Nome</StyledTableCell>
              <StyledTableCell key={4} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Telefone</StyledTableCell>
              <StyledTableCell key={5} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Data</StyledTableCell>
              <StyledTableCell key={6} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Valor</StyledTableCell>
              <StyledTableCell key={7} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Taxa (%)</StyledTableCell>
              <StyledTableCell key={8} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Líquido</StyledTableCell>
              <StyledTableCell key={9} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Tipo de pagamento</StyledTableCell>
              <StyledTableCell key={10} align={'left'} style={{ minWidth: 100, fontWeight: 'bold', }}>Status do pagamento</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tickets?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((ticket: ITicket) => {

                const value = ticket.value || 0
                const entranceFee = ticket.payments?.length ? ticket.payments[0]?.pixEntranceFee || 0 : 0
                const entranceFeeValue = (entranceFee * value) / 100
                const netValue = value - entranceFeeValue
                const paymentType = ticket.payments?.length ? ticket.payments[0]?.type : undefined

                return (
                  <StyledTableRow hover role="checkbox" tabIndex={-1} key={ticket._id}
                    style={{ cursor: 'pointer', }}
                    onClick={() => {
                      navigate(RoutesNames.ticket.replace(':id', ticket?._id))
                    }}
                  >
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={1} align={'left'}>
                      <ImageComponent
                        src={`${dotEnvProductionState.payload?.APIURL}/img/${ticket.prizeDraw?.imgUrl}`}
                        style={{ objectFit: 'cover', height: 40, width: 70, display: 'flex', margin: 0, padding: 0, borderRadius: 5, }}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={2} align={'left'}>{ticket.prizeDraw?.title}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={3} align={'left'}>{ticket.customer?.name}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={4} align={'left'}>{ticket.customer?.phoneNumber}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={5} align={'left'}>{`${moment(ticket.dateTime as string).format('DD/MM/YYYY HH:mm')}`}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={6} align={'left'}>{NumberUtils.getNumberStr((ticket.value as number))}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={7} align={'left'}>{NumberUtils.getNumberStr(entranceFee)}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={8} align={'left'}>{NumberUtils.getNumberStr(netValue)}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={9} align={'left'}>{paymentType == PaymentTypes.pix ? 'Pix' : 'Cambista'}</StyledTableCell>
                    <StyledTableCell style={{
                      color: ticket.canceled ? 'red' : 'none',
                      textDecoration: ticket.canceled ? 'line-through' : 'none',
                    }} key={10} align={'left'}>
                      {PaymentTransactionsStatusDescriptions.filter(s => s.value === (ticket.payments?.length ? ticket.payments[0].status : 0))[0]?.description}
                    </StyledTableCell>
                    <StyledTableCell key={11} align={'left'}> {ticket.prizeDraw?.closed ? <div style={{
                      background: 'blue',
                      color: 'white',
                      padding: '3px 5px',
                      borderRadius: 5,
                      width: 'fit-content',
                    }}>ENCERRADO</div> : <></>}
                    </StyledTableCell>
                    <StyledTableCell key={12} align={'left'}> {ticket.prizeDraw?.closed ? <div style={{
                      background: ArrayUtils.checkArrays((ticket.numbers || []), (ticket.prizeDraw?.drawnNumbers || []))
                        ? 'green' : 'red',
                      color: 'white',
                      padding: '3px 5px',
                      borderRadius: 5,
                      width: 'fit-content',
                    }}>{ArrayUtils.checkArrays((ticket.numbers || []), (ticket.prizeDraw?.drawnNumbers || []))
                      ? 'GANHOU' : 'PERDEU'}</div> : <></>}
                    </StyledTableCell>

                    <StyledTableCell>
                      {ticket.prizeDraw?.allowRenewNumbers ? <MoreIconComponent onClickOpen={() => {
                        setRenewedTicketId(ticket._id)
                        setOpenDialog(true)
                      }} /> : null}
                    </StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>

      {tickets?.length ? <ThemeProvider theme={themeWithLocale}>
        <TablePagination style={{
          minHeight: 60,
          overflow: 'hidden'
        }}
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={tickets?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </ThemeProvider> : <></>}
    </div>
  </div>
}