import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Authentication } from '../../models'
import { IDefaultAction, IDefaultState } from '../types'
import { CommonUtils, WebUtils } from 'fwork.common.typescript'
import { IApiResult } from 'fwork.common.typescript/api'
import { CommonApi } from '../../api/common'

export interface IAuthenticationAction<UserType> extends IDefaultAction {
  payload?: Authentication<UserType>,
  filter?: {
    name: string,
    pwd: string,
  }
}

export interface IAuthenticationState<UserType> extends IDefaultState {
  payload?: Authentication<UserType>,
  customPayload: any,
}

const cookie = WebUtils.getCookie('@authenticationState')
const cookieObj = cookie ? JSON.parse(cookie) : null

const initState: IAuthenticationState<any> = cookieObj || {}

export const authenticationStateLoadFromApi = createAsyncThunk(
  'authenticationStateLoadFromApi',
  async (arg: {
    apiUrl: string,
    payload: any,
    onSuccess?: (result: IApiResult<Authentication<any>>) => void,
    onCustomPayload?: (reponse: IApiResult<Authentication<any>>) => any,
    onError?: (msg?: string) => void,
    checkAuthCallBack?: (authentication?: Authentication<any>) => boolean | undefined
  }) => {
    const response: IApiResult<Authentication<any>> = await CommonApi.getAuthentication(arg.apiUrl, arg.payload)
    let customData

    if (response.success) {
      if (arg.checkAuthCallBack ? arg.checkAuthCallBack(response.data) : true) {
        if (arg.onSuccess)
          arg.onSuccess(response)

        if (arg.onCustomPayload && response.data) {
          customData = arg.onCustomPayload(response)
          if (CommonUtils.isPromise(customData))
            customData = await customData
        }
      }
      else {
        response.success = false
        response.msg = 'Acesso negado'
        response.data = undefined

        if (arg.onError)
          arg.onError(response.msg)
      }
    }
    else if (arg.onError)
      arg.onError(response.msg)

    return {
      ...response,
      customData
    }
  }
)

export const authenticationSlice = createSlice({
  name: 'authenticationTest',
  initialState: initState,
  reducers: {
    // set: function <UserType>(state: IAuthenticationState<UserType>, action: PayloadAction<IAuthenticationAction<UserType>>) {
    //   state.payload = action.payload.payload
    //   WebUtils.setCookie('@authenticationState', '', -1)
    // },
    set: (state, action) => {
      state.payload = action.payload.payload
      state.options = action.payload.options
      state.filter = action.payload.filter
      WebUtils.setCookie('@authenticationState', JSON.stringify(state), 1)
    },
    logout: (state) => {
      state.payload = undefined
      state.options = undefined
      state.filter = undefined
      WebUtils.setCookie('@authenticationState', '', -1)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authenticationStateLoadFromApi.pending, (state) => {
      if (!state.options)
        state.options = {}
      state.options.loading = true
    })
    builder.addCase(authenticationStateLoadFromApi.fulfilled, (state, response) => {
      if (state.options)
        state.options.loading = false
      state.payload = response.payload.data
      state.customPayload = response.payload.customData
      if (state.payload)
        WebUtils.setCookie('@authenticationState', JSON.stringify(state), 1)
    })
    builder.addCase(authenticationStateLoadFromApi.rejected, (state) => {
      if (state.options)
        state.options.loading = false
    })
  }
})

export const { logout: authenticationStateLogout, set: authenticationStateSet } = authenticationSlice.actions

export default authenticationSlice.reducer