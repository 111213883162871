import { CircularProgress } from "@mui/material"
import { ColorUtils, CommonUtils, StringUtils } from "fwork.common.typescript"
import React, { useEffect, useState } from "react"
import { MdErrorOutline } from 'react-icons/md'

export const ImageComponent = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
  const [error, setError] = useState<boolean>()

  useEffect(() => {
    // VERIFICAR ESSE CODIGO, ESTADO DERIVADO
    setError(!props.src)
  }, [props.src])

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setError(true)
    if (props?.onError)
      props.onError(event)
  }

  return <>
    {error ?
      <MdErrorOutline
        style={{ color: '#EEE', ...props?.style }}
      /> : <img
        onError={onError}
        alt=''

        {...props}
      />}
  </>
}

export interface ImageOrInitLettersComponentProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  initLettersText?: string,
  onlyFirstAndLastWord?: boolean,
}

export const ImageOrInitLettersComponent = (props: ImageOrInitLettersComponentProps) => {
  const [error, setError] = useState<boolean>()

  const { initLettersText, onlyFirstAndLastWord, onClick, ...rest } = props

  useEffect(() => {
    // VERIFICAR ESSE CODIGO, ESTADO DERIVADO
    setError(!props.src)
  }, [props.src])

  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setError(true)
    if (props?.onError)
      props.onError(event)
  }

  return <>
    {error ?
      (
        !CommonUtils.isNullOrEmpty(initLettersText) ?
          <div
            onClick={props.onClick}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: 'large',
              color: 'white',

              border: `3px solid white`,
              background: ColorUtils.randomDarkColorHexStr(),
              boxSizing: 'border-box',
              ...props.style
            }}>
            {StringUtils.getInitLetters(initLettersText!, onlyFirstAndLastWord).toUpperCase()}
          </div> :
          <MdErrorOutline
            style={{ color: '#EEE', ...props?.style }}
          />
      ) :
      <img
        onError={onError}
        alt=''

        {...rest}
      />}
  </>
}

export interface ProgressProps {
  progress: boolean | undefined
  size: number
  circularColorIndicator: string
}

export interface ImageProgressComponentProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  progressProps?: ProgressProps | undefined
}

export const ImageProgressComponent = (props: ImageProgressComponentProps) => {
  const { progressProps, ...rest } = props
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    loadImage()
  }, [props.src])
  
  useEffect(() => {
    setLoading(progressProps?.progress as boolean)
  }, [progressProps?.progress])

  const loadImage = async () => {
    setLoading(true)
    try {
      await new Promise((resolve, reject) => {
        const img = new Image();
        img.src = props.src || '';
        img.onload = () => {
          resolve(true)
        };
        img.onerror = () => {
          reject();
        }
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading ?
        <CircularProgress size={progressProps?.size} style={{ color: progressProps?.circularColorIndicator }} />
        :
        <img alt='' {...rest} />
      }
    </>
  )
}

