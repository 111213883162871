import { Button } from "@mui/material"
import { PaymentTransactionsStatusDescriptions } from "@sl7/payment.lib.common.ts/models"
import { ITicket, IUser } from "rifa.lib.common.ts/models"
import { ApiClientUtils } from "fwork.common.typescript/apiClient"
import { Form10x20Styled, FormItem10x20Styled, IAuthenticationState } from "fwork.react.apptemplate"
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from "react"
import { isMobile } from 'react-device-detect'
import { useDispatch, useSelector } from "react-redux"
import axios from "../../../apiClient/axios"
import { TicketsApiClient } from "../../../apiClient/tickets"
import { PrizeDrawItemCard } from "../../../common/components/prizeDrawItemCard"
import { TicketItemCardComponent, TicketItemType } from "../../../common/components/ticketSmall"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { toggleLoading } from "../../../redux/reducers/loadingBar.slice"
import { RootState } from "../../../redux/store"
import { FormatTextToPrint } from "./formatTextToPrint"
import { TicketPayments } from "./payments"

export const TicketComponent = (props: {
  id: String
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [ticket, setTicket] = useState<ITicket | undefined>()
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)

  const dispatch = useDispatch()

  useEffect(() => {
    if (dotEnvProductionState.payload)
      loadTicket()
  }, [dotEnvProductionState])

  const loadTicket = async () => {
    dispatch(toggleLoading())
    try {
      const response = await new TicketsApiClient(axios).get({
        where: {
          _id: props.id
        },
        nested: 'customer,user,prizeDraw'
      })
      if (response.success) {
        if (response.data?.payload?.length)
          setTicket(response.data.payload[0])
      }
      else
        enqueueSnackbar('Não foi possível consultar o bilhete', { variant: 'error' })
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
    finally {
      dispatch(toggleLoading())
    }
  }

  const reloadPayments = async () => {
    dispatch(toggleLoading())
    try {
      const response = await new TicketsApiClient(axios).getPayments({
        id: ticket?._id
      })
      if (response.success) {
        if (response.data?.payload?.length)
          setTicket((prev) => {
            if (prev)
              prev.payments = response.data?.payload
            return { ...prev } as ITicket
          })
      }
      else
        enqueueSnackbar('Não foi possível carregar os pagamentos do bilhete', { variant: 'error' })
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
    finally {
      dispatch(toggleLoading())
    }
  }

  const handlePrint = async () => {
    const status = ticket?.payments ? ticket?.payments[0]?.status : undefined

    const text = FormatTextToPrint({
      userName: ticket?.customer?.name as string,
      email: ticket?.customer?.email,
      cpf: ticket?.customer?.cpf,
      phone: ticket?.customer?.phoneNumber,
      prizeDrawTitle: ticket?.prizeDraw?.title as any,
      numbers: ticket?.numbers as any,
      ticketValue: ticket?.unitValue,
      value: ticket?.value,
      dateTime: ticket?.dateTime,
      status: PaymentTransactionsStatusDescriptions.filter(s => s.value === status)[0].description,
    })

    const deepLinkURL = `sl7://printermanager/print?text=${text}`;
    window.location.href = deepLinkURL
  }

  return <div
    aria-label="TicketComponent"
    className="TicketComponent" style={{
      position: 'relative',
      flexGrow: 1
    }}>
    {/* BACKGROUND */}
    <div style={{
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundImage: `url(${dotEnvProductionState.payload?.APIURL}/img/${ticket?.prizeDraw?.imgUrl})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      opacity: .1,
    }}></div>

    {/* CONTENT */}
    <Form10x20Styled
      className="PrizeDrawComponent-content"
      style={{
        maxWidth: 1024,
        margin: 'auto',
      }}>

      {ticket?.prizeDraw ? <FormItem10x20Styled style={{ width: '100%', zIndex: 1, overflow: 'hidden' }}>
        <PrizeDrawItemCard prizeDraw={ticket?.prizeDraw} cutDescription={30} />
      </FormItem10x20Styled> : <></>}

      {ticket?.customer ? <FormItem10x20Styled style={{ width: '100%', zIndex: 1, overflow: 'hidden' }}>
        <TicketItemCardComponent ticket={ticket} type={TicketItemType.user} />
      </FormItem10x20Styled> : <></>}

      {!ticket?.canceled ? (
        ticket?.payments?.length ?
          <FormItem10x20Styled style={{ width: '100%', zIndex: 1, overflow: 'hidden' }}>
            <TicketPayments payments={ticket?.payments} onResetTimer={loadTicket} />
          </FormItem10x20Styled> :
          <FormItem10x20Styled style={{ width: '100%', zIndex: 1, overflow: 'hidden' }}>
            <Button variant="contained" onClick={reloadPayments}>Pagamento</Button>
          </FormItem10x20Styled>) :
        <></>}
      {!ticket?.canceled && isMobile && authenticationState.payload ? <FormItem10x20Styled style={{ width: '100%', zIndex: 1, overflow: 'hidden' }}>
        <Button variant="contained" onClick={handlePrint} style={{ textTransform: 'none' }}>Imprimir Comprovante</Button>
      </FormItem10x20Styled> : <></>}
    </Form10x20Styled>
  </div>
}