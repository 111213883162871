import { Button, InputAdornment, TextField } from "@mui/material"
import { useSnackbar } from "notistack"
import React, { useRef } from "react"
import { FaLock, FaUserCircle } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import { Authentication } from "../../models"
import { authenticationStateLoadFromApi } from "../../redux/reducers/authenticationtest.slice"
import { IApiResult } from "fwork.common.typescript/api"

// export interface ILoginOptions<UserType> extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
export interface ILoginOptions<UserType> extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  apiUrl: string,
  // register account route
  registerRouter?: string,
  // success on login
  onSuccess?: (result: IApiResult<Authentication<UserType>>) => void,
  // used to additional information after login
  onCustomPayload?: (response: IApiResult<Authentication<any>>) => any,
  // payload that must be sent to the api
  onPayloadOverride?: (login: string, pwd: string) => any,
  // text to display in user input
  userPlaceHolder?: string,

  checkAuthCallBack?: (authentication?: Authentication<UserType>) => boolean | undefined
}

export function LoginComponent<UserType>(props: ILoginOptions<UserType>) {

  const { apiUrl, registerRouter, onSuccess, onCustomPayload, onPayloadOverride, userPlaceHolder, checkAuthCallBack, ...rest } = props

  const dispatch = useDispatch<any>()
  const login = useRef<HTMLInputElement>()
  const pwd = useRef<HTMLInputElement>()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const doLogin = () => {
    let payload = onPayloadOverride ? onPayloadOverride(login.current?.value || '', pwd.current?.value || '') : {
      login: (login.current as any).value,
      pwd: (pwd.current as any).value
    }

    dispatch(authenticationStateLoadFromApi({
      apiUrl,
      payload,
      onSuccess,
      onCustomPayload,
      checkAuthCallBack,
      onError(msg?) {
        enqueueSnackbar(msg, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          }
        })
      },
    }))
  }

  return <form
    {...rest}

    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: 'center',
      paddingInline: 10,
      gap: 20,
      maxWidth: 350,
      margin: 'auto',
      width: '100%',
      boxSizing: 'border-box',
      ...rest.style,
    }}
  >
    <h2 style={{
      alignSelf: 'center'
    }}>Entrar</h2>

    <TextField
      fullWidth
      id="userEmailOrTel"
      variant="outlined"
      placeholder={userPlaceHolder ?? "Digite seu nome de usuário"}
      autoComplete="username"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaUserCircle />
          </InputAdornment>
        ),
      }}
      inputRef={login}
    />

    <TextField
      fullWidth
      id="userPassword"
      autoComplete="new-password"
      variant="outlined"
      placeholder="Sua Senha"
      type="password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaLock />
          </InputAdornment>
        ),
      }}
      inputRef={pwd}
    />

    <Button
      fullWidth
      variant="contained"
      onClick={doLogin}>
      Confirmar
    </Button>

    {registerRouter ? <div style={{
      fontSize: 12,
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      gap: 5,
    }}>
      <p>Ainda não tem conta?</p>
      <Link to={registerRouter}>Cadastre-se</Link>
    </div> : <></>}

    <Button
      fullWidth
      variant="text"
      onClick={() => navigate(-1)}
    >
      Voltar
    </Button>
  </form>
}