import { Form10x20Styled, FormItem10x20Styled } from "fwork.react.apptemplate"
import React from "react"
import { useNavigate } from 'react-router-dom'
import { IPrizeDraw } from "rifa.lib.common.ts/models"
import { PrizeDrawItemCard } from "../../../common/components/prizeDrawItemCard"
import { RoutesNames } from "../../../common/globals"

export const PrizeDrawsComponent = (props: {
  prizedraws: IPrizeDraw[]
}) => {
  const navigate = useNavigate()

  const onPrizeDrawCardClick = (prizeDraw: IPrizeDraw) => {
    navigate(RoutesNames.prizeDraw.replace(':id', prizeDraw._id))
  }
  
  return <Form10x20Styled className="PrizeDrawsComponent" style={{
    justifyContent: 'center'
  }}>
    {props.prizedraws.length > 1 ? props.prizedraws?.map(d => {
      return <FormItem10x20Styled key={d._id} className="CardWrapper">
        <PrizeDrawItemCard style={{
          height: '100%'
        }} prizeDraw={d} cutDescription={30} onClick={onPrizeDrawCardClick} />
      </FormItem10x20Styled>
    }) : <></>}
  </Form10x20Styled>
}