import axios from 'axios'
import { WebUtils } from 'fwork.common.typescript'
import { IAuthenticationState } from 'fwork.react.apptemplate'
import { IUser } from 'rifa.lib.common.ts/models'
import { IDotEnvProduction, IDotEnvProductionState } from '../redux/reducers/dotenvProduction.slice'

axios.interceptors.request.use(async config => {
  let authenticationStr = WebUtils.getCookie('@authenticationState')
  let authentication: IAuthenticationState<IUser> | undefined = undefined
  try {
    if (authenticationStr)
      authentication = JSON.parse(authenticationStr)
  } catch (error) {

  }

  let dotEnvProductionStr = WebUtils.getCookie('@dotEnvProduction')
  let dotEnvProduction: IDotEnvProduction | undefined
  try {
    if (dotEnvProductionStr)
      dotEnvProduction = JSON.parse(dotEnvProductionStr)
  } catch (error) {

  }

  config.headers['Authorization'] = `Bearer ${authentication?.payload?.token}`
  config.headers['Cache-Control'] = 'no-cache'
  config.headers['Pragma'] = 'no-cache'
  config.headers['Expires'] = '0'
  config.headers['Access-Control-Allow-Origin'] = '*'

  return config
}, error => Promise.reject(error))

export default axios
