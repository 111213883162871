import { createSlice } from '@reduxjs/toolkit'
import { WebUtils } from 'fwork.common.typescript'
import { IDefaultAction, IDefaultState } from 'fwork.react.apptemplate'
import { ILocalSettings } from '../../common/model'

export interface ILocalSettingsAction extends IDefaultAction {
  payload: ILocalSettings
}

export interface ILocalSettingsState extends IDefaultState {
  payload: ILocalSettings
}

const fromCookieObj = WebUtils.getCookieObj<ILocalSettingsState>('@localSettingsState')

const initState: ILocalSettingsState = fromCookieObj || {
  payload: {}
}

export const localSettingsSlice = createSlice({
  name: 'localSettingsSlice',
  initialState: initState,
  reducers: {
    set: (state, action: ILocalSettingsAction) => {
      state.payload = action.payload
      WebUtils.setCookie('@localSettingsState', JSON.stringify(state), 1)
    },
    
  },
  extraReducers: (builder) => {
    
  }
})

export const { set: localSettingsStateSet } = localSettingsSlice.actions

export default localSettingsSlice.reducer