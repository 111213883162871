import { IAppBarOptions, ImageComponent } from "fwork.react.apptemplate";
import React from "react";
import { useSelector } from "react-redux";
import { AppBarUserOptionsComponent } from "../../../common/components/appBarOptions/appBarUserOptions";
import { ISettingsState } from "../../../redux/reducers/settings.slice";
import { RootState } from "../../../redux/store";

export const appBarOptions = (): IAppBarOptions => {
  const settingsState: ISettingsState = useSelector((state: RootState) => state.settingsState)

  return {
    show: true,

    leftContentOptions: {
      show: true,
    },

    mainContent: <div style={{
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    }}>
      {settingsState.payload?.length ? <ImageComponent style={{
        maxHeight: 50,
        marginRight: 10
      }} src={settingsState.payload[0].companyLogoUrl?.toString()} /> : <></>}

      <span style={{ flexGrow: 1 }}></span>

      <AppBarUserOptionsComponent style={{
        color: 'white'
      }}/>
    </div >,

    style: {
      background: '#166CC8',
      minHeight: 65,
      padding: '5px 0px',
      boxSizing: 'border-box',
    },
  }
}