import { Button, InputAdornment } from "@mui/material";
import TextField from '@mui/material/TextField';
import { ICustomer } from "rifa.lib.common.ts/models";
import { CommonUtils, StringUtils, WebUtils } from "fwork.common.typescript";
import { SelectImgComponent } from "fwork.react.apptemplate";
import React, { useEffect, useState } from "react";
import { BiUserCircle } from 'react-icons/bi';
import { FaIdCard, FaPhone, FaUserAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";

export const CustomerDataComponent = (props: {
  onConfirm?: (customer: ICustomer | undefined) => void,
  onCancel?: () => void
}) => {

  const customerStr = WebUtils.getCookie('customer-data-tmp')
  const tmpCustomer = customerStr ? StringUtils.jsonDecode(customerStr) : undefined
  const [customer, setCustomer] = useState<ICustomer>(tmpCustomer || {})

  useEffect(() => {
    WebUtils.setCookie('customer-data-tmp', JSON.stringify({
      ...customer,
      imgProfileUrl: null
    }), 365)
  }, [customer])

  return <>
    <span>Preencha os dados abaixo para continuar com sua compra:</span>

    <SelectImgComponent
      id="customerDataImgPicker"
      emptyElement={<BiUserCircle style={{
        width: 150,
        height: 150,
        color: 'silver'
      }} />}
      onSelectImg={(src) => {
        setCustomer({
          ...customer,
          imgProfileUrl: src
        })
      }}
      imgProps={{
        src: customer?.imgProfileUrl?.toString() || '',
        style: {
          height: 150,
          width: 150,
          borderRadius: '100%',
          objectFit: 'cover'
        }
      }} />

    <TextField
      value={customer?.name || ''}
      fullWidth
      variant="outlined"
      placeholder="Seu nome"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaUserAlt />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setCustomer({
        ...customer,
        name: e.target.value
      })}
    />

    <TextField
      error={!([null, ''].indexOf(customer?.phoneNumber?.toString() || '') !== -1) && !CommonUtils.isValidPhone(customer?.phoneNumber?.toString() || '')}
      helperText={!([null, ''].indexOf(customer?.phoneNumber?.toString() || '') !== -1) && !CommonUtils.isValidPhone(customer?.phoneNumber?.toString() || '') ? 'Telefone inválido' : ''}
      value={customer?.phoneNumber || ''}
      fullWidth
      variant="outlined"
      placeholder="Telefone"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaPhone />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setCustomer({
        ...customer,
        phoneNumber: e.target.value
      })}
    />

    <TextField
      error={(customer?.email?.toString() !== null && customer?.email?.toString()) &&
        !CommonUtils.isValidEmail(customer?.email?.toString()) ? true : false}
      helperText={(customer?.email?.toString() !== null && customer?.email?.toString()) &&
        !CommonUtils.isValidEmail(customer?.email?.toString()) ? 'Email inválido' : ''}
      value={customer?.email || ''}
      fullWidth
      variant="outlined"
      placeholder="Email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MdMail />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setCustomer({
        ...customer,
        email: e.target.value
      })}
    />

    <TextField
      error={!([null, ''].indexOf(customer?.cpf?.toString() || '') !== -1) && !CommonUtils.isValidCPF(customer?.cpf?.toString() || '')}
      helperText={!([null, ''].indexOf(customer?.cpf?.toString() || '') !== -1) && !CommonUtils.isValidCPF(customer?.cpf?.toString() || '') ? 'cpf inválido' : ''}
      value={customer?.cpf || ''}
      fullWidth
      variant="outlined"
      placeholder="cpf"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FaIdCard />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setCustomer({
        ...customer,
        cpf: e.target.value
      })}
    />

    <Button
      fullWidth
      variant="contained"
      onClick={() => {
        if (props.onConfirm) props.onConfirm(customer)
      }}
    >
      Confirmar
    </Button>
    <Button
      fullWidth
      variant="text"
      onClick={() => {
        if (props.onCancel) props.onCancel()
      }}
    >
      Voltar
    </Button>
  </>
}