import { FormControl, InputLabel, MenuItem, Select, styled, TextField } from "@mui/material"
import { ApiClientUtils } from "fwork.common.typescript/apiClient"
import { authenticationStateSet, FormItemStyled, FormStyled, IAuthenticationState, SelectImgComponent, TextPwdComponent } from "fwork.react.apptemplate"
import { useSnackbar } from "notistack"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { IUser, UserTypes } from "rifa.lib.common.ts/models"
import axios from '../../../apiClient/axios'
import { UsersApiClient } from "../../../apiClient/users"
import { ConfirmDialogComponent } from "../../../common/components/dialogComponent/confirm"
import { IDotEnvProductionState } from "../../../redux/reducers/dotenvProduction.slice"
import { RootState } from "../../../redux/store"
import { DEFAULTGAP } from "../../globals"
import { HomeTypes } from "../../types"
import { HeaderComponent } from "../miscelaneous/header"

const ImgWrapper = styled(FormItemStyled)(({ theme }) => ({
  width: '100% !important',
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start'
  }
}))

export const UserComponent = (props: {
  data?: IUser,
  onConfirm?: () => void,
  onCancel?: () => void,
  onDelete?: () => void,
}) => {
  const authenticationState: IAuthenticationState<IUser> = useSelector((state: RootState) => state.authenticationState)
  const dotEnvProductionState: IDotEnvProductionState = useSelector((state: RootState) => state.dotEnvProductionState)
  const [data, setData] = useState<IUser>(props.data || {} as IUser)
  const { enqueueSnackbar } = useSnackbar()
  const [confirmedPwd, setConfirmedPwd] = useState<string>()
  const dispatch = useDispatch<any>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [contentType, setContentType] = useState(HomeTypes.user)


  const confirm = async () => {
    if (data.pwd != confirmedPwd)
      return

    const apiResult = await (data._id ? ApiClientUtils.put({
      axios,
      apiUrl: `${dotEnvProductionState.payload?.APIURL}/users`,
      data,
    }) : ApiClientUtils.post({
      axios,
      apiUrl: `${dotEnvProductionState.payload?.APIURL}/users`,
      data,
    }))
    if (apiResult.success) {
      enqueueSnackbar('Alterações salvas com sucesso', { variant: 'success' })

      if (authenticationState.payload?.user._id == data._id) {
        if (apiResult.data && authenticationState.payload?.token) {
          const tmpAuthState: IAuthenticationState<IUser> = {
            ...authenticationState,
            payload: {
              ...authenticationState.payload,
              user: apiResult.data as IUser
            }
          }

          dispatch(authenticationStateSet(tmpAuthState))
        }
      }
      if (props.onConfirm)
        props.onConfirm()
    }
    else {
      enqueueSnackbar(apiResult.msg, { variant: 'error' })
    }
  }

  const remove = async () => {
    try {
      const apiResult = await new UsersApiClient(axios).delete(data._id)

      if (apiResult.success) {
        enqueueSnackbar('Item excluído com sucesso', { variant: 'success' })
        if (props.onDelete)
          props.onDelete()
      }
      else {
        enqueueSnackbar(apiResult.msg, { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar(ApiClientUtils.getErrorMessage(error), { variant: 'error' })
    }
  }

  return <>
    <ConfirmDialogComponent
      title="Usuário"
      text='Deseja realmente excluír?'
      open={openDialog}
      onConfirm={() => {
        remove()
        setOpenDialog(false)
      }}
      onCancel={() => {
        setOpenDialog(false)
      }}
      onClose={() => setOpenDialog(false)}
    />

    {contentType === HomeTypes.user ? <>
      <HeaderComponent
        props={{
          style: {
            paddingInline: DEFAULTGAP
          }
        }}
        title={<>
          <h3>
            {`Usuário ${data?.name || ''}, `}
          </h3>
          <h3 style={{
            color: 'grey', marginLeft: 10
          }}>
            {data?.login?.toString()}
          </h3>
        </>}

        onConfirmClick={confirm}
        onCancelClick={props.onCancel}
        onRemoveClick={data.name === 'root' ? undefined : () => setOpenDialog(true)}
      />
      <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        <ImgWrapper>
          <SelectImgComponent
            id="userImgPicker"
            onSelectImg={(src: string) => {
              setData({
                ...data,
                imgProfileUrl: src
              })
            }}
            imgProps={{
              src: data.imgProfileUrl?.toUpperCase().indexOf(';BASE64,') != -1 ? data.imgProfileUrl?.toString() : `${dotEnvProductionState.payload?.APIURL}/img/${data.imgProfileUrl?.toString()}`,
              style: {
                width: 200,
                height: 200,
                borderRadius: '100%',
                objectFit: 'cover',
              }
            }} />
        </ImgWrapper>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField label='Nome'
            disabled={data.name == 'root' && data._id}
            value={data?.name?.toString() || ''}
            onChange={(e) => setData({
              ...data,
              name: e.target.value
            })}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextField label='Login'
            // disabled={data.login == 'root'}
            value={data?.login?.toString() || ''}
            onChange={(e) => setData({
              ...data,
              login: e.target.value
            })}
          />
        </FormItemStyled>

        {data?.name == 'root' && data._id ? <></> :
          <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
            <FormControl>
              <InputLabel id="userType">Tipo de usuário</InputLabel>
              <Select
                label='Tipo de Usuário'
                id="userType"
                value={data.userType?.toString() || ''}
                onChange={(e) => setData({
                  ...data,
                  userType: parseInt(e.target.value)
                })}
              >
                <MenuItem value={UserTypes.admin}>Administrador</MenuItem>
                <MenuItem value={UserTypes.seller}>Padrão</MenuItem>
              </Select>
            </FormControl>
          </FormItemStyled>}

      </FormStyled>

      <FormStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
        {data._id ? <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextPwdComponent
            props={{
              autoComplete: 'new-password',
              label: 'Para alterar a senha entre com a senha anterior',
              value: data?.oldPwd?.toString() || '',
              onChange: (e) => setData({
                ...data,
                oldPwd: e.target.value
              }),
            }}
          />
        </FormItemStyled> : <></>}

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextPwdComponent
            props={{
              autoComplete: 'new-password',
              fullWidth: true,
              label: data._id ? 'Insira a nova senha' : 'Senha',
              onChange: (e) => {
                setData({
                  ...data,
                  pwd: e.target.value
                })
              },
            }}
          />
        </FormItemStyled>

        <FormItemStyled rowGap={DEFAULTGAP} columnGap={DEFAULTGAP * 2}>
          <TextPwdComponent
            props={{
              autoComplete: 'new-password',
              fullWidth: true,
              label: 'Confirme a nova senha',
              onChange: (e) => {
                setConfirmedPwd(e.target.value)
              },
              error: data.pwd != confirmedPwd,
              helperText: data.pwd != confirmedPwd ? 'As senhas não conferem' : null
            }}
          />
        </FormItemStyled>

      </FormStyled></> : <></>}
  </>
}